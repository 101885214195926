import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Algorithms,
  AnalysisProtocolTask,
  IAssetROI,
  IAssetROIWithModels,
  LabelUtils,
  Sample,
  User,
} from "@telespot/sdk";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  acceptAIROIs,
  analysisState,
  clearViewerCtxState,
  updateSampleAnalysisState,
  viewerType,
} from "../../+state";

import {
  ROI,
  selectActiveROIS,
  setSelectedROIs,
  setROIs,
  removeROIs,
  updateROI,
  POI,
  Label,
  selectSelectedROIS,
  activeAnalysisIds,
  selectedLabelsWithAnalysisReq,
  AnalysisLabel,
  AnalysisRequest,
  createAnalysisFromROIs,
  selectROISelectionTasks,
  selectROIsFromRegion,
  totalCount,
  labelCount,
  loadStats,
  selectLabelsArray,
  createFindingsFromROIs,
  IAnalysis,
  selectSegmentationTasks,
  selectVisibleVideoRois,
  selectSelectedVideoRois,
  IVideoROIFrame,
  removeVideoROIs,
  setSelectedVideoROIs,
  updateROITime,
  createAnalysisFromVideoROI,
  createFindingsFromVideoROI,
  setVideoROIs,
  selectMicroVisibleROIS,
  selectMosaicsVisibleROIS,
} from "../../state";
import { AuthService } from "@telespot/web-core";

@Injectable({
  providedIn: "root",
})
export class RoiService implements OnDestroy {
  private _destroy$ = new Subject<void>();

  private analysisLabelsAndRequest: {
    analysisLabels: AnalysisLabel[];
    selectedLabels: Label[];
    activeAnalysis: IAnalysis[];
    analysesRequest: AnalysisRequest[];
    analysisCreation: boolean;
    findingsCreation: boolean;
  };
  private roiActiveTasks: AnalysisProtocolTask[];
  private segmentationActiveTasks: AnalysisProtocolTask[];
  private allLabels: Label[];
  private labelUtils = new LabelUtils();
  private selectedRois: (ROI | POI)[];
  private selectedVideoRois: IVideoROIFrame[];
  private activeAnalysisIds: string[];
  private _viewerType: string;

  private _defaultROISize = 100;
  private _selectModelEvent$ = new Subject<void>();

  public readonly selectedModelsEvent$ = this._selectModelEvent$.asObservable();
  public readonly assetROIs$ = this._store.select(selectActiveROIS);
  public readonly microVisibleROIs$ = this._store.select(
    selectMicroVisibleROIS
  );
  public readonly mosaicsVisibleROIs$ = this._store.select(
    selectMosaicsVisibleROIS
  );
  public readonly visibleVideoROIs$ = this._store.select(
    selectVisibleVideoRois
  );
  public readonly totalCount$ = this._store.select(totalCount);
  public readonly selectedRois$ = this._store.select(selectSelectedROIS);
  public readonly loadedStats$ = this._store.select(labelCount);
  public readonly analysisState$ = this._store.select(analysisState);
  public readonly viewerType$ = this._store.select(viewerType);

  get selectedROIS() {
    return this.selectedRois;
  }

  constructor(
    private _store: Store<{ rois: any }>,
    private _auth: AuthService
  ) {
    this._store
      .select(selectROISelectionTasks)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tasks) => (this.roiActiveTasks = tasks));
    this._store
      .select(selectSegmentationTasks)
      .pipe(takeUntil(this._destroy$))
      .subscribe((tasks) => (this.segmentationActiveTasks = tasks));
    this._store
      .select(selectLabelsArray)
      .pipe(takeUntil(this._destroy$))
      .subscribe((labels) => (this.allLabels = labels));
    this._store
      .select(selectSelectedROIS)
      .pipe(takeUntil(this._destroy$))
      .subscribe((rois) => (this.selectedRois = rois));
    this._store
      .select(selectSelectedVideoRois)
      .pipe(takeUntil(this._destroy$))
      .subscribe((videoRois) => (this.selectedVideoRois = videoRois));
    this._store
      .select(activeAnalysisIds)
      .pipe(takeUntil(this._destroy$))
      .subscribe((ids) => (this.activeAnalysisIds = ids));
    this._store
      .select(selectedLabelsWithAnalysisReq)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (analysisLabelsAndReq) =>
          (this.analysisLabelsAndRequest = analysisLabelsAndReq)
      );
    this._store
      .select(viewerType)
      .pipe(takeUntil(this._destroy$))
      .subscribe((viewerType) => (this._viewerType = viewerType));
  }

  // METHODS

  ngOnDestroy() {
    this._destroy$.next();
  }

  clearModels() {
    this._store.dispatch(clearViewerCtxState());
  }

  addROIs(rois: IAssetROI[]) {
    const newAnalysis = this.analysisLabelsAndRequest.analysisCreation;
    const newFindings = this.analysisLabelsAndRequest.findingsCreation;
    if (
      this.analysisLabelsAndRequest.analysisLabels.length < 1 &&
      this.analysisLabelsAndRequest.selectedLabels.length < 1
    )
      return;

    if (newAnalysis) {
      this._store.dispatch(
        createAnalysisFromROIs({
          analysesRequest: this.analysisLabelsAndRequest.analysesRequest,
          selectedLabels: this.analysisLabelsAndRequest.selectedLabels,
          rois,
          currentUser: this._auth.currentUser.toPointer(),
        })
      );
    } else if (newFindings) {
      this._store.dispatch(
        createFindingsFromROIs({
          analysis: this.analysisLabelsAndRequest.activeAnalysis,
          selectedLabels: this.analysisLabelsAndRequest.selectedLabels,
          rois,
          currentUser: this._auth.currentUser.toPointer(),
        })
      );
    } else {
      const roisToAdd = rois.map((roi) => ({
        x: roi.x,
        y: roi.y,
        w: roi?.w,
        h: roi?.h,
        labels: this.analysisLabelsAndRequest.analysisLabels,
      }));
      this._store.dispatch(
        setROIs({
          rois: roisToAdd,
          createdBy: this._auth.currentUser.toPointer(),
        })
      );
    }
  }

  addVideoROIs(roi: Partial<IVideoROIFrame>) {
    const newAnalysis = this.analysisLabelsAndRequest.analysisCreation;
    const newFindings = this.analysisLabelsAndRequest.findingsCreation;
    if (
      this.analysisLabelsAndRequest.analysisLabels.length < 1 &&
      this.analysisLabelsAndRequest.selectedLabels.length < 1
    )
      return;

    if (newAnalysis) {
      this._store.dispatch(
        createAnalysisFromVideoROI({
          analysesRequest: this.analysisLabelsAndRequest.analysesRequest,
          selectedLabels: this.analysisLabelsAndRequest.selectedLabels,
          roi,
        })
      );
    } else if (newFindings) {
      this._store.dispatch(
        createFindingsFromVideoROI({
          analysis: this.analysisLabelsAndRequest.activeAnalysis,
          selectedLabels: this.analysisLabelsAndRequest.selectedLabels,
          roi,
        })
      );
    } else {
      const roiToAdd = {
        x: roi.x,
        y: roi.y,
        w: roi?.w,
        h: roi?.h,
        t1: roi.t1,
        analysisId: this.analysisLabelsAndRequest.analysisLabels[0].analysisId,
        findingId: this.analysisLabelsAndRequest.analysisLabels[0].findingId,
        labels: this.analysisLabelsAndRequest.analysisLabels[0].labels,
      };
      this._store.dispatch(setVideoROIs({ rois: [roiToAdd] }));
    }
  }

  removeSelectedROIs() {
    if (this._viewerType === "video") {
      this._store.dispatch(
        removeVideoROIs({ selectedROIs: this.selectedVideoRois })
      );
      this._store.dispatch(setSelectedVideoROIs({ rois: [], replace: true }));
    } else {
      this._store.dispatch(removeROIs({ selectedROIs: this.selectedRois }));
      this.selectROIs(null, true);
    }
  }

  removeROIs(rois: ROI[]) {
    this._store.dispatch(removeROIs({ rois: rois || [] }));
  }

  removeVideoROIs(rois: IVideoROIFrame[]) {
    this._store.dispatch(removeVideoROIs({ rois: rois || [] }));
  }

  selectROIs(rois: ROI | ROI[], replace: boolean = false): ROI[] {
    const newRois: ROI[] = rois ? (rois instanceof Array ? rois : [rois]) : [];
    this._store.dispatch(setSelectedROIs({ rois: newRois, replace }));
    return newRois; // TODO WHY
  }

  selectVideoROIs(rois: Partial<IVideoROIFrame>[], replace: boolean = false) {
    const newRois = rois ? (rois instanceof Array ? rois : [rois]) : [];
    this._store.dispatch(setSelectedVideoROIs({ rois: newRois, replace }));
  }

  selectROIsInsideRegion(bounds: IAssetROI) {
    this._store.dispatch(
      selectROIsFromRegion({
        bounds,
        activeAnalysisIds: this.activeAnalysisIds,
      })
    );
  }

  public updateROIposition({
    roi,
    position,
  }: {
    roi: ROI;
    position: Partial<ROI>;
  }): void {
    this._store.dispatch(updateROI({ roi, changes: position }));
  }

  public updateROItime({
    roi,
    t1,
    t2,
  }: {
    roi: IVideoROIFrame;
    t1: number;
    t2: number;
  }): void {
    this._store.dispatch(updateROITime({ roi, changes: { t1, t2 } }));
  }

  public acceptAIROIs(rois: IAssetROIWithModels[]) {
    this._store.dispatch(acceptAIROIs({ rois }));
  }

  public loadStats(
    sample: Sample,
    createdBy: User | Algorithms,
    analysisStateId: string
  ) {
    this._store.dispatch(loadStats({ sample, createdBy, analysisStateId }));
  }

  public updateAnalysisState(historyId: string, sample: string) {
    this._store.dispatch(updateSampleAnalysisState({ historyId, sample }));
  }

  /**
   * Retrieves the assigned color for the specified model(s)
   *
   * @param {(SelectedLabels)} labels
   * @param {boolean} [opaque=false]
   * @returns
   * @memberof RoiService
   */

  getModelColor(
    labels: string[],
    opaque: boolean = false,
    segmentation: boolean = false
  ) {
    return this.labelUtils.getLabelColor(
      labels,
      opaque,
      segmentation ? this.segmentationActiveTasks : this.roiActiveTasks
    );
  }

  public getComplementaryColor(color: string): string {
    const { r, g, b, a } = this.rgbaStringToRgb(color);

    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;

    return a !== undefined
      ? `rgba(${compR}, ${compG}, ${compB}, ${a})`
      : `rgb(${compR}, ${compG}, ${compB})`;
  }

  private rgbaStringToRgb(color: string): {
    r: number;
    g: number;
    b: number;
    a?: number;
  } {
    const rgbaMatch = color.match(
      /rgba?\((\d+), (\d+), (\d+),?\s*(\d?.\d+)?\)/
    );

    const [, r, g, b, a] = rgbaMatch;

    return {
      r: parseInt(r, 10),
      g: parseInt(g, 10),
      b: parseInt(b, 10),
      a: a ? parseFloat(a) : 1,
    };
  }

  // getModelDisplayName(labels: string[]) {
  //   return this.labelUtils.getModelDisplayName(labels, this.roiActiveTasks);
  // }

  getLabelName(labels: string[]) {
    if (!labels || !labels.length) return;
    return (
      (labels || [])
        .map(
          (label) => this.allLabels.find((l) => l.uuid === label)?.value ?? ""
        )
        .join(",") || "(no tags)"
    );
  }

  public updateRoiSize(newSize: number) {
    this._defaultROISize = newSize;
  }

  public get defaultROISize(): number {
    return this._defaultROISize;
  }
}
