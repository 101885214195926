import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  clearReviewCount,
  clearSampleCounterState,
  loadStatsSuccess,
  replaceCounter,
  updateReviewCount,
  updateStats,
  updateStatsOnReviewMode,
} from "./sample-counters.actions";

import { NewXOI, POI, ROI } from "../analysis";

export interface LabelCount {
  labelId: string;
  rois: number;
}

export interface MultiLabelCount {
  [key: string]: number;
}

export interface SingleLabelCount {
  [key: string]: number;
}
export interface LabelCountReview {
  labelCount: LabelCount[];
  analysisStateId: string;
}

export interface LabelCountEntity {
  analysisStateId: string;
  labelCount: LabelCount[];
  totalCount: number;
  multiLabelCount: MultiLabelCount;
  singleLabelCount: SingleLabelCount;
}
export interface SampleCounterState {
  labelCount: EntityState<LabelCountEntity>;
  labelCountReview: LabelCountReview;
  totalCountReview: number;
  multiLabelReviewCount: MultiLabelCount;
  singleLabelReviewCount: SingleLabelCount;
  error: string | null;
}

export const labelCountAdapter = createEntityAdapter<LabelCountEntity>({
  selectId: (labelCountEntity) => labelCountEntity.analysisStateId,
});

export const initialSampleCounterState: SampleCounterState = {
  labelCount: labelCountAdapter.getInitialState(),
  labelCountReview: { analysisStateId: undefined, labelCount: [] },
  totalCountReview: 0,
  multiLabelReviewCount: {},
  singleLabelReviewCount: {},
  error: null,
};

export function extractSingleAndMultiCount(rois: any[]) {
  const roiLabels = rois.map(
    (roi) => ({
      labels: [
        ...roi.labels.reduce(
          (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
          []
        ),
      ],
    }),

    []
  );
  const singleLabels = roiLabels
    .filter((roi) => roi.labels.length === 1)
    .reduce((acc, currLabel) => [...acc, ...currLabel.labels], []);
  const multiLabels = roiLabels
    .filter((roi) => roi.labels.length !== 1)
    .reduce((acc, roi) => {
      return [...acc, roi.labels.sort().join("/")];
    }, []);

  const singleLabelCount = singleLabels?.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  const multiLabelCount = multiLabels?.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});
  return {
    singleLabelCount: singleLabelCount || {},
    multiLabelCount: multiLabelCount || {},
  };
}

export function updateSingleMultiCount(currCountDetail, countDetailUpdate) {
  const { multiLabelCount: currMultiCount, singleLabelCount: currSingleCount } =
    currCountDetail;

  const {
    multiLabelCount: currMultiUpdate,
    singleLabelCount: currSingleUpdate,
  } = countDetailUpdate;

  const newMultiCount = { ...currMultiCount };
  const newSingleCount = { ...currSingleCount };

  Object.keys(currMultiUpdate || {}).forEach((key) => {
    const labelKey = Object.keys(currMultiCount || {}).find(
      (item) => item === key
    );
    if (labelKey !== undefined) {
      newMultiCount[key] += currMultiUpdate[key];
    } else {
      newMultiCount[key] = currMultiUpdate[key];
    }
  });
  Object.keys(currSingleUpdate || {}).forEach((key) => {
    const labelKey = Object.keys(newSingleCount).find((item) => item === key);
    if (labelKey !== undefined) {
      newSingleCount[key] += currSingleUpdate[key];
    } else {
      newSingleCount[key] = currSingleUpdate[key];
    }
  });

  return { newMultiCount, newSingleCount };
}

export function makeCountNegative(
  singleMultiCount: Record<string, Record<string, number>>
): Record<string, Record<string, number>> {
  return Object.keys(singleMultiCount).reduce(
    (acc, count) => ({
      ...acc,
      [count]: {
        ...Object.keys(singleMultiCount[count]).reduce(
          (acc, key) => ({
            ...acc,
            [key]: -singleMultiCount[count][key],
          }),
          {}
        ),
      },
    }),
    {}
  );
}

export function filterNegativeCount(count) {
  const filteredCount = {};
  Object.keys(count).forEach((key) => {
    if (count[key] > 0) {
      filteredCount[key] = count[key];
    }
  });

  return filteredCount;
}

export function getCurrentLabelCount(
  state: SampleCounterState,
  analysisStateId: string
) {
  return Object.values(state.labelCount.entities).find(
    (labelCount) => labelCount.analysisStateId === analysisStateId
  );
}

export function getOptionsCount(labels) {
  return labels?.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});
}

export const sampleCounterReducer = createReducer(
  initialSampleCounterState,
  on(clearSampleCounterState, () => initialSampleCounterState),
  on(
    loadStatsSuccess,
    (
      state,
      {
        labelCount,
        totalCount,
        multiLabelCount,
        singleLabelCount,
        analysisStateId,
      }
    ) => {
      return {
        ...state,
        labelCount: labelCountAdapter.upsertOne(
          {
            analysisStateId,
            labelCount,
            totalCount,
            multiLabelCount: multiLabelCount ?? {},
            singleLabelCount: singleLabelCount ?? {},
          },
          state.labelCount
        ),
      };
    }
  ),
  on(
    updateStats,
    (
      state,
      { optionsCount, roisToUpdate, singleAndMultiCount, analysisStateId }
    ) => {
      const currentCount = getCurrentLabelCount(state, analysisStateId);

      let labelCount = [...(currentCount?.labelCount || [])];

      const totalCount: number =
        ((currentCount as LabelCountEntity)?.totalCount || 0) + roisToUpdate;

      Object?.keys(optionsCount)?.forEach((option) => {
        const index = labelCount.findIndex((item) => item.labelId === option);
        if (index !== -1) {
          labelCount[index] = {
            ...labelCount[index],
            rois: labelCount[index].rois + optionsCount[option],
          };
        } else {
          labelCount.push({
            labelId: option,
            rois: optionsCount[option],
          });
        }
      });

      labelCount = labelCount.filter((item) => item.rois > 0);

      const multiLabelCount = (currentCount as LabelCountEntity)
        .multiLabelCount;
      const singleLabelCount = (currentCount as LabelCountEntity)
        .singleLabelCount;

      let { newMultiCount: newMultiCount, newSingleCount: newSingleCount } =
        updateSingleMultiCount(
          { multiLabelCount, singleLabelCount },
          singleAndMultiCount
        );

      newMultiCount = filterNegativeCount(newMultiCount);
      newSingleCount = filterNegativeCount(newSingleCount);

      return {
        ...state,
        labelCount: labelCountAdapter.updateOne(
          {
            id: analysisStateId,
            changes: {
              labelCount: labelCount,
              multiLabelCount: newMultiCount,
              singleLabelCount: newSingleCount,
              totalCount: totalCount > 0 ? totalCount : 0,
            },
          },
          state.labelCount
        ),
      };
    }
  ),
  on(
    updateStatsOnReviewMode,
    (state, { optionsCount, roisToUpdate, singleAndMultiCount }) => {
      const currentCount = state.labelCountReview;

      let labelCount = [...(currentCount?.labelCount || [])];

      const totalCount: number = state.totalCountReview + roisToUpdate;

      Object?.keys(optionsCount)?.forEach((option) => {
        const index = labelCount.findIndex((item) => item.labelId === option);
        if (index !== -1) {
          labelCount[index] = {
            ...labelCount[index],
            rois: labelCount[index].rois + optionsCount[option],
          };
        } else {
          labelCount.push({
            labelId: option,
            rois: optionsCount[option],
          });
        }
      });

      labelCount = labelCount.filter((item) => item.rois > 0);

      const multiLabelCount = state.multiLabelReviewCount;
      const singleLabelCount = state.singleLabelReviewCount;

      let { newMultiCount: newMultiCount, newSingleCount: newSingleCount } =
        updateSingleMultiCount(
          { multiLabelCount, singleLabelCount },
          singleAndMultiCount
        );

      newMultiCount = filterNegativeCount(newMultiCount);
      newSingleCount = filterNegativeCount(newSingleCount);

      return {
        ...state,
        labelCountReview: {
          analysisStateId: currentCount.analysisStateId,
          labelCount,
        },
        totalCountReview: totalCount > 0 ? totalCount : 0,
        multiLabelReviewCount: newMultiCount,
        singleLabelReviewCount: newSingleCount,
      };
    }
  ),
  on(
    updateReviewCount,
    (
      state,
      {
        additionCount,
        deletionCount,
        roisToUpdate,
        analysisStateId,
        multiSingleCount,
      }
    ) => {
      let labelCount;
      let totalCount: number;
      let newMultiReviewCount;
      let newSingleReviewCount;

      if (state?.labelCountReview?.labelCount.length === 0) {
        const currentCount = getCurrentLabelCount(state, analysisStateId);
        labelCount = [...(currentCount?.labelCount || [])];
        totalCount = currentCount?.totalCount + roisToUpdate;

        //Filter negative count just in case
        newMultiReviewCount = filterNegativeCount(
          multiSingleCount.newMultiCount
        );
        newSingleReviewCount = filterNegativeCount(
          multiSingleCount.newSingleCount
        );
      } else {
        labelCount = [...(state?.labelCountReview?.labelCount || [])];
        totalCount = state?.totalCountReview + roisToUpdate;

        const { newMultiCount, newSingleCount } = updateSingleMultiCount(
          {
            multiLabelCount: state.multiLabelReviewCount,
            singleLabelCount: state.singleLabelReviewCount,
          },
          {
            multiLabelCount: multiSingleCount.newMultiCount,
            singleLabelCount: multiSingleCount.newSingleCount,
          }
        );

        newMultiReviewCount = filterNegativeCount(newMultiCount);
        newSingleReviewCount = filterNegativeCount(newSingleCount);
      }
      [additionCount, deletionCount].map((optionsCount) =>
        Object?.keys(optionsCount)?.forEach((option) => {
          const index = labelCount.findIndex((item) => item.labelId === option);
          if (index !== -1) {
            labelCount[index] = {
              ...labelCount[index],
              rois: labelCount[index].rois + optionsCount[option],
            };
          } else {
            labelCount.push({
              labelId: option,
              rois: optionsCount[option],
            });
          }
        })
      );

      labelCount = labelCount.filter((item) => item.rois > 0);

      return {
        ...state,
        labelCountReview: { analysisStateId, labelCount },
        totalCountReview: totalCount > 0 ? totalCount : 0,
        multiLabelReviewCount: newMultiReviewCount,
        singleLabelReviewCount: newSingleReviewCount,
      };
    }
  ),
  on(clearReviewCount, (state) => ({
    ...state,
    totalCountReview: 0,
    labelCountReview: { analysisStateId: undefined, labelCount: [] },
    multiLabelReviewCount: {},
    singleLabelReviewCount: {},
  })),
  on(replaceCounter, (state) => {
    return {
      ...state,
      labelCount: labelCountAdapter.updateOne(
        {
          id: state?.labelCountReview?.analysisStateId,
          changes: {
            labelCount: state?.labelCountReview.labelCount,
            totalCount: state?.totalCountReview ?? 0,
            singleLabelCount: state?.singleLabelReviewCount ?? {},
            multiLabelCount: state?.multiLabelReviewCount ?? {},
          },
        },
        state.labelCount
      ),
    };
  })
);
