import {
  Algorithms,
  Analysis,
  Finding,
  PipelineStep,
  StepTask,
  User,
} from "@telespot/sdk";
import {
  AnalysisChange,
  IFinding,
  IVideoROI,
  POI,
  ProtocolSpecification,
  ROI,
} from "../../state";
import { ISampleItem } from "../../models/i-sample-item";

export class FindingMapper {
  public static toStateFindings(finding: Finding): IFinding {
    let data;
    switch (finding.type) {
      case StepTask.TEXT:
        data = finding.data?.content[0]?.value ?? "";
        break;
      case StepTask.CLASSIFICATION: {
        const findingData = Object.keys(
          finding.data?.content[0]?.options || {}
        );
        data = finding.pipelineStep.params?.multiple
          ? findingData
          : findingData[0];
        break;
      }
      case StepTask.SEGMENTATION:
        data = finding.data?.url;
        break;
      default:
        data = undefined;
        break;
    }
    return {
      id: finding.id,
      analysisId: finding.analysis.id,
      data,
      synced: true,
      taskId: finding.pipelineStep.id,
      assetId: finding.analysis.asset?.id,
      version: finding.version,
      uuid: finding.uuid,
      createdBy:
        finding.creatorEntity === "user"
          ? User.createWithoutData(finding?.creatorId).toPointer()
          : Algorithms.createWithoutData(finding?.creatorId).toPointer(),
    };
  }

  public static fromStateFinding(
    finding: IFinding,
    changes: AnalysisChange<string>[],
    rois: (ROI | POI)[],
    videoRois: IVideoROI[],
    protocols: ProtocolSpecification[],
    assetsInfo: ISampleItem[]
  ) {
    const parseFinding = new Finding();
    const isNew = finding.id.includes("new:");
    const isCopy = finding.id.startsWith("copy:");

    const getAnalysisId = (id) =>
      id.startsWith("copy:") ? id.substring(id.indexOf("/replace:") + 9) : id;

    const isUser = finding.createdBy.className === User.className;

    const creatorEntity = isUser ? "user" : "algorithm";
    const creatorId = finding.createdBy.objectId;

    parseFinding.creatorId = creatorId;
    parseFinding.creatorEntity = creatorEntity;

    parseFinding.pipelineStep = PipelineStep.createWithoutData(
      finding.taskId
    ) as PipelineStep;

    const analysisId =
      changes.find((c) => c.previous === finding.analysisId)?.current ??
      getAnalysisId(finding.analysisId);

    parseFinding.analysis = analysisId
      ? (Analysis.createWithoutData(analysisId) as Analysis)
      : null;

    const taskType = protocols
      .reduce((acc, p) => [...acc, ...p.tasks], [])
      .find((t) => t.stepId === finding.taskId)?.type;
    parseFinding.type = taskType;

    if (isCopy) {
      parseFinding.origin = Finding.createWithoutData(
        finding.id.substring(
          finding.id.indexOf("copy:") + 5,
          finding.id.indexOf("/replace:")
        )
      ) as Finding;
    }
    const assetInfo = finding.assetId
      ? assetsInfo.find((assetInfo) => assetInfo.assetId === finding.assetId)
      : undefined;

    parseFinding.version = isNew ? 1 : finding.version + 1;
    parseFinding.data = FindingMapper.toFindingData(
      finding,
      taskType,
      rois,
      videoRois,
      assetInfo
    );

    parseFinding.uuid = finding.uuid;

    return parseFinding;
  }

  // public static getFindingsFromMosaicRois(items: RoiItem[]) {
  //   const uniqueFindings = [];

  //   items.map((item) => {
  //     const isIncluded = uniqueFindings.find(
  //       (finding) => finding.id === item.findingVersionId
  //     );

  //     if (!isIncluded) {
  //       uniqueFindings.push({
  //         id: item.findingVersionId,
  //         analysisId: item.analysisId,
  //         data: undefined,
  //         synced: true,
  //         taskId: item.stepId,
  //         assetId: item.assetId,
  //         version: item.findingVersion,
  //         uuid: item.findingId,
  //         createdBy:
  //           item.creatorEntity === "user"
  //             ? User.createWithoutData(item?.creatorId).toPointer()
  //             : Algorithms.createWithoutData(item?.creatorId).toPointer(),
  //       });
  //     }
  //   });

  //   return uniqueFindings;
  // }

  public static denormalizeRoi(refItem, item) {
    const assetWidth = refItem?.width ?? 1;
    const assetHeight = refItem?.height ?? 1;

    const x = Number(Number(item.x0) * assetWidth);
    const y = Number(Number(item.y0) * assetHeight);
    const w = Number((Number(item.x1) - Number(item?.x0)) * assetWidth);
    const h = Number((Number(item?.y1) - Number(item?.y0)) * assetHeight);

    return { x, y, w, h };
  }

  public static normalizeRoi(refItem, item) {
    const assetWidth = refItem?.width ?? 1;
    const assetHeight = refItem?.height ?? 1;
    const x0 = Number(item.x / assetWidth);
    const y0 = Number(item.y / assetHeight);
    const x1 = Number((item.x + item["w"] ?? 0) / assetWidth);
    const y1 = Number((item.y + item["h"] ?? 0) / assetHeight);

    return { x0, y0, x1, y1 };
  }

  public static toFindingData(
    finding: IFinding,
    type: string,
    rois?: (ROI | POI)[],
    videoRois?: IVideoROI[],
    assetInfo?: ISampleItem
  ) {
    let updatedData;
    const findingROIs = [];
    (rois || []).forEach((r) => {
      const labels =
        (r.labels || []).find((l) => l.findingId === finding.id)?.labels ?? {};

      if (Object.keys(labels).length < 1) return;

      const coords = this.normalizeRoi(assetInfo, r);

      findingROIs.push({
        ...coords,
        labels,
      });
    });

    const findingVideoRois = (videoRois || []).reduce((acc, roi) => {
      return {
        ...acc,
        [roi.id]: [
          ...roi.keyframes.map((keyframe) => {
            const coords = this.normalizeRoi(assetInfo, {
              x: keyframe.x,
              y: keyframe.y,
              w: keyframe.w,
              h: keyframe.h,
            });
            return {
              ...coords,
              timestamp: keyframe.timestamp,
              labels: keyframe.labels,
            };
          }),
        ],
      };
    }, {});

    switch (type) {
      case StepTask.TEXT:
        updatedData = { content: [{ value: finding.data ?? "" }] };
        break;
      case StepTask.CLASSIFICATION:
        updatedData = {
          content: [
            {
              options:
                finding.data instanceof Array
                  ? finding.data.reduce(
                      (acc, curr) => ({ ...acc, [curr]: 1 }),
                      {}
                    )
                  : { [finding.data]: 1 },
            },
          ],
        };
        break;
      case StepTask.POSITION:
        updatedData = {
          content: [...findingROIs],
        };
        break;
      case StepTask.ROITRACKING:
        updatedData = {
          content: findingVideoRois,
        };
        break;
      case StepTask.ROIDETECTION:
        updatedData = {
          content: [...findingROIs],
        };
        break;
      case StepTask.SEGMENTATION:
        updatedData = { url: "" };
        break;
      default:
        updatedData = undefined;
        break;
    }

    return updatedData;
  }
}
