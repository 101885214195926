import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  ISampleItem,
  IVideoROIFrame,
  RoiService,
  preSyncAnalysis,
  selectRefStripElements,
  setAsset,
} from "@telespot/analysis-refactor/data-access";
import { AuthService } from "@telespot/web-core";
import { Asset, Sample } from "@telespot/sdk";
import { SettingsService } from "@telespot/settings/data-access";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, take } from "rxjs/operators";

import { BaseAssetViewer } from "../../directives/base-asset-viewer";

export interface IVideoSource {
  url: string;
  fileName?: string;
  thumbnail?: string;
}

@Component({
  selector: "ts-video-viewer",
  templateUrl: "./video-viewer.component.html",
  styleUrls: ["./video-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "videoViewer",
})
export class VideoViewerComponent
  extends BaseAssetViewer
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() src: Sample;
  @Output() resizeEvent = new EventEmitter<void>();

  private readonly DRAG_THRESHOLD = 20;
  private _ENABLE_ZOOM;

  public readonly isIOS = this._platform.IOS;

  dragging = false;
  lastMouseX: number;
  lastMouseY: number;
  zoomLevel = 1;
  translateX = 0;
  translateY = 0;
  private dragDistance = 0;
  isLoading = true;

  @ViewChild("videoSelectionTemplate", { read: TemplateRef, static: true })
  videoSelectionTemplate;

  private _selectedVideoIndexB$ = new BehaviorSubject<number>(0);
  get selectedVideoIndex$() {
    return this._selectedVideoIndexB$.asObservable();
  }

  private _enableDebugB$ = new BehaviorSubject<boolean>(false);
  get enableDebug$() {
    return this._enableDebugB$.asObservable();
  }

  private _canvasTransform: number[] = [1, 0, 0, 1, 0, 0];
  get canvasTransform() {
    return [...this._canvasTransform];
  }
  private _canvasTransformB$ = new BehaviorSubject<number[]>(
    this._canvasTransform
  );
  get canvasTransform$() {
    return this._canvasTransformB$.asObservable();
  }

  sources: IVideoSource[] = [];
  refStripItems: Observable<ISampleItem[]> = this.store.select(
    selectRefStripElements
  );

  ratio: number;

  frameDrawn$ = new EventEmitter<void>();

  private _useCanvas: boolean;
  get useCanvas() {
    return this._useCanvas;
  }
  videoDuration = 0;
  readonly verbose: boolean = false;
  @ViewChild("video", { static: true }) video: ElementRef<HTMLVideoElement>;
  @ViewChild("tracker") tracker: any;
  @ViewChild("canvas", { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild("bigCanvasContainer", { static: true }) canvasContainer: any;
  @ViewChild("resizeHandle", { static: true }) resizeHandle: ElementRef;
  @ViewChild("videoBar", { static: true }) videoBar: ElementRef;

  ctx: CanvasRenderingContext2D;
  resizeListener: any;
  private _roiRemovalActive = false;
  playbackSpeed = 1;
  speedDropdownOpen = false;

  private startY!: number;
  private startHeight!: number;

  @ViewChild("trackbar", { read: ElementRef }) trackBar: ElementRef<any>;
  @Output() bookmarkClicked = new EventEmitter<Partial<IVideoROIFrame>>();
  @Output() bookmarkAltClicked = new EventEmitter<IVideoROIFrame>();

  videoCurrentTime = 0;

  private _showVideoSelectionOverlay = new BehaviorSubject<boolean>(false);
  selectedAssetId: number;
  zoom = 1;

  private _videoToCanvasContainerFactor: number;
  private _assets: Asset[] = [];

  playListCount: number;

  slowSeek: Subject<boolean> = new Subject<boolean>();

  toggleLoop() {
    this.video.nativeElement.loop = !this.video.nativeElement.loop;
  }

  setPlaybackSpeed(speed: number) {
    this.playbackSpeed = speed;
    this.video.nativeElement.playbackRate = speed;
    this.toggleSpeedDropdown();
  }

  toggleSpeedDropdown() {
    this.speedDropdownOpen = !this.speedDropdownOpen;
  }

  constructor(
    private settings: SettingsService,
    @Inject(DOCUMENT) private _document: Document,
    private _dialog: MatDialog,
    private _platform: Platform,
    private _auth: AuthService,
    protected store: Store,
    protected _roiService: RoiService
  ) {
    super();
  }

  ngOnDestroy(): void {
    if (this.resizeListener)
      window.removeEventListener("resize", this.resizeListener);

    this.canvas.nativeElement.removeEventListener(
      "mousedown",
      this.activatePan
    );
    this.canvas.nativeElement.removeEventListener("mousemove", this.pan);
    this._document.removeEventListener("mouseup", this.stopPan);
  }

  async configure() {
    await this.store
      .select(selectRefStripElements)
      .pipe(
        take(1),
        map((items) => {
          items.map((item) => {
            const asset = Asset.createWithoutData(item.assetId);
            asset.assetFile = item.assetFile;
            this._assets.push(asset);
          });
        })
      )
      .toPromise();

    if (this.src) {
      switch (typeof this.src) {
        case "object":
          if (this.src instanceof Sample && this._assets.length > 0) {
            this.playListCount = this.numItems;
            this.sources = this._assets.map((a) => {
              return {
                url: a.assetFileURL,
                fileName: a.assetFileName,
                thumbnail: a.thumbnail,
              };
            });
            if (this.sources.length > 1 && !this.isMobile) {
              this.toggleVideoSelectionOverlay(true);
            }
            console.log(`Configured`, this.src);
          } else {
            throw new Error(`Video viewer currently supports Sample src only`);
          }
          break;
        default:
          throw new Error(`Unsupported src`);
      }
    }
    this._viewerReady.next(true);
  }

  async ngOnInit() {
    this._useCanvas =
      !this.isIOS && this.settings.getBoolean("useCanvasVideoViewer", true);
    this._ENABLE_ZOOM = this.settings.getBoolean("enableVideoZoom", false);
    await this.configure();
    this._setupHandlers();
    this.selectVideo(0);
  }

  ngAfterViewInit(): void {
    if (this.verbose) console.log("ngAfterViewInit");
    // this.canvas = this._document.getElementById('bigCanvas') as HTMLCanvasElement;
    // this.resizeCanvas();
    this.ctx = this.canvas.nativeElement.getContext("2d");
    if (this.verbose) console.log(this.ctx);
    this.initializeResizing();
  }

  initializeResizing() {
    const resizeHandle = this.resizeHandle.nativeElement;

    resizeHandle.addEventListener("mousedown", this.onMouseDown.bind(this));
    document.addEventListener("mousemove", this.onMouseMove.bind(this));
    document.addEventListener("mouseup", this.onMouseUp.bind(this));
  }

  getTimelineMarkers(videoDuration) {
    if (videoDuration === 0) return [];
    const roundedDuration = Math.round(videoDuration);
    const DEFAULT_N_MARKERS = 25;
    const numMarkers =
      roundedDuration < DEFAULT_N_MARKERS ? roundedDuration : DEFAULT_N_MARKERS;
    const markerInterval = videoDuration / numMarkers;

    const timelineMarkers = [];

    for (let i = 0; i <= numMarkers; i++) {
      const time = i * markerInterval;
      timelineMarkers.push({
        time: this.formatTime(time),
        position: (time / videoDuration) * 100,
      });
    }

    return timelineMarkers;
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
  }

  onMouseDown(event: MouseEvent) {
    this.startY = event.clientY;
    this.startHeight = this.videoBar.nativeElement.offsetHeight;
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent) {
    const MAX_HEIGHT = 300;
    if (this.startY !== undefined && this.startHeight !== undefined) {
      const draggedHeight = this.startHeight + (this.startY - event.clientY);
      const newHeight = draggedHeight < MAX_HEIGHT ? draggedHeight : MAX_HEIGHT;
      this.resizeEvent.emit();

      if (newHeight > 50) {
        this.videoBar.nativeElement.style.height = `${newHeight}px`;
        this.resizeHandle.nativeElement.style.bottom = `${newHeight}px`;
      }
    }
  }

  onMouseUp() {
    this.startY = undefined;
    this.startHeight = undefined;
  }

  relativeTime(time: number = this.videoCurrentTime) {
    return (time / this.video.nativeElement.duration) * 100;
  }

  selectVideo(index: number) {
    if (this.src === undefined) return;
    index = Math.max(0, Math.min(this.playListCount, index));
    if (index !== this.selectedAssetId) {
      this.isLoading = true;
      this.selectedAssetId = index;
      this.store.dispatch(setAsset({ asset: this._assets[index] }));
      this.itemIndexChanged.emit(index);
      this.setSource(this.sources[this.selectedAssetId].url);
      this._selectedVideoIndexB$.next(index);
    }
  }

  setSource(src: string) {
    const request = new XMLHttpRequest();
    request.responseType = "blob";
    request.open("get", src, true);
    request.setRequestHeader("X-Parse-Session-Token", this._auth.sessionToken);
    request.onreadystatechange = (e) => {
      if (
        request.readyState === XMLHttpRequest.DONE &&
        request.status === 200
      ) {
        this._document
          .getElementById("videoSource")
          ?.setAttribute("src", request.responseURL);
        (this.video.nativeElement as HTMLVideoElement).load();
      }
    };
    request.send(null);
  }

  private _setupHandlers() {
    // if (!this.trackBar) this.trackBar = this._document.getElementsByClassName('videoProgressLine')[0] as HTMLElement;

    if (!this._useCanvas) {
      this.video.nativeElement.addEventListener("wheel", (event) => {
        if (event.ctrlKey || event.metaKey) {
          event.stopPropagation();
          this.zoom += Math.sign(event.deltaY) * 0.05;
        } else {
          this._seekBy(event);
        }
      });
    }
    // (this.tracker).addEventListener('drag',this.seek,false)
    this._document.addEventListener("keydown", (e) => {
      if (
        (e.ctrlKey && e.key === "s") ||
        (e.ctrlKey && e.key === "S") ||
        (e.metaKey && e.key === "s") ||
        (e.metaKey && e.key === "S")
      ) {
        this.store.dispatch(preSyncAnalysis());
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (e.shiftKey) {
        this.slowSeek.next(true);
        this._document.addEventListener("keyup", this.disableSlowMode);
      }
      if (e.metaKey || e.ctrlKey) {
        if (!this._roiRemovalActive) {
          // console.log("Activated roi removal");
          this._roiRemovalActive = true;
          this._document.addEventListener(
            "keyup",
            (event) => {
              // console.log("Deactivated roi removal");
              this._roiRemovalActive = false;
            },
            { once: true }
          );
        }
      }
      switch (e.code) {
        case "Space":
          if (e.target["nodeName"] !== "BUTTON") {
            this.playPause();
          }
          break;
        case "ArrowLeft":
          this._seekBy(e.metaKey || e.ctrlKey ? -0.03 : -1);
          break;
        case "ArrowRight":
          this._seekBy(e.metaKey || e.ctrlKey ? 0.03 : 1);
          break;
        case "Delete":
          this._roiService.removeSelectedROIs();
          break;
        case "Backspace":
          this._roiService.removeSelectedROIs();
          break;
      }
    });

    this._document.addEventListener("keyup", (e) => {
      switch (e.code) {
        case "NumpadAdd":
          // this.zoom += 0.05;
          this._zoomIn(0.05);
          break;
        case "NumpadSubtract":
          // this.zoom -= 0.05;
          this._zoomIn(-0.05);
          break;
        case "KeyD":
          this._enableDebugB$.next(!this._enableDebugB$.value);
          break;
      }
    });

    // // REVIEW: this is probably not needed, we detect canvas events.
    this.video.nativeElement.addEventListener("click", (event) => {
      this.video.nativeElement.paused
        ? this.video.nativeElement.play()
        : this.video.nativeElement.pause();
    });

    this.video.nativeElement.addEventListener("loadedmetadata", (e) => {
      console.log("Video metadata: ", e);
      this.videoDuration = this.video.nativeElement.duration;
      this.resizeCanvas();
    });

    if (this._useCanvas) {
      this.canvas.nativeElement.addEventListener(
        "wheel",
        (event) => {
          if (event.ctrlKey || event.metaKey) {
            // event.preventDefault();
            // if (this._ENABLE_ZOOM) {
            const mousePosition = { x: event.clientX, y: event.clientY };
            this._zoomIn(-Math.sign(event.deltaY) * 0.05, mousePosition);

            // }
          } else {
            this._seekBy(event);
          }
        },
        { passive: true }
      );
      this.canvas.nativeElement.addEventListener("click", this.click);
      this.videoBar.nativeElement.addEventListener("click", () =>
        this._roiService.selectVideoROIs([], true)
      );

      this.canvas.nativeElement.addEventListener("mousedown", this.activatePan);
      this.canvas.nativeElement.addEventListener("mousemove", this.pan);
      this._document.addEventListener("mouseup", this.stopPan.bind(this));

      this.resizeListener = this.resizeCanvas.bind(this);
      window.addEventListener("resize", this.resizeListener);
      this.prepareCanvasPlayback();
    }
  }

  private _zoomIn(delta: number, mousePosition?: { x: number; y: number }) {
    if (!mousePosition) return;

    // Current canvas transform values
    const [a, b, c, d, e, f] = this._canvasTransform;

    const newZoom = Math.max(0.1, this.zoomLevel + delta);
    const zoomFactor = newZoom / this.zoomLevel;
    this.zoomLevel = newZoom;

    // Calculate new translation
    const canvasRect = this.canvas.nativeElement.getBoundingClientRect();
    const mouseX = mousePosition.x - canvasRect.left;
    const mouseY = mousePosition.y - canvasRect.top;

    // Adjust translate values
    const translateX = e - (mouseX - e) * (zoomFactor - 1);
    const translateY = f - (mouseY - f) * (zoomFactor - 1);

    // Update canvas transform matrix
    this._canvasTransform[0] = a * zoomFactor; // scaleX
    this._canvasTransform[1] = b * zoomFactor; // skewX
    this._canvasTransform[2] = c; // skewY
    this._canvasTransform[3] = d * zoomFactor; // scaleY
    this._canvasTransform[4] = translateX; // translateX
    this._canvasTransform[5] = translateY; // translateY

    this._canvasTransformB$.next([...this._canvasTransform]);
  }

  private _translate(deltaX: number, deltaY: number) {
    this._canvasTransform[4] += deltaX;
    this._canvasTransform[5] += deltaY;
    this._canvasTransformB$.next([...this._canvasTransform]);
  }

  activatePan = (event: MouseEvent) => {
    if (!event.shiftKey) {
      this.dragging = true;
      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
      this.dragDistance = 0;
    }
  };

  pan = (event: MouseEvent) => {
    if (this.dragging) {
      const deltaX = (event.clientX - this.lastMouseX) * 0.5;
      const deltaY = (event.clientY - this.lastMouseY) * 0.5;

      this.dragDistance += Math.abs(deltaX) + Math.abs(deltaY);

      if (this.dragDistance >= this.DRAG_THRESHOLD) {
        this._translate(deltaX, deltaY);
      }

      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
    }
  };
  stopPan(event: MouseEvent) {
    this.dragging = false;
    this.lastMouseX = undefined;
    this.lastMouseY = undefined;
    event.preventDefault();
  }

  click = (event) => {
    if (this.dragDistance < this.DRAG_THRESHOLD) this.playPause(event);
    this._roiService.selectVideoROIs([], true);
  };

  disableSlowMode = (e) => {
    this.slowSeek.next(false);
    window.removeEventListener("keyup", this.disableSlowMode);
  };

  prepareCanvasPlayback() {
    this.canvasContainer = this._document.getElementById(
      "bigCanvasContainer"
    ) as HTMLElement;
    // this.canvas = this._document.getElementById('bigCanvas') as HTMLCanvasElement;
    this.ctx = this.canvas.nativeElement.getContext("2d");
    const video = this.video.nativeElement;
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          console.log(`Automatic playback started!`);
        })
        .catch((error) => {
          console.log(`Auto-play was prevented. Showing paused UI.`);
        });
    }
    setInterval(this.renderVideo.bind(this), 33);
  }

  resizeCanvas() {
    if (!this.video.nativeElement || !this.canvasContainer) return;
    if (this.verbose)
      console.log(
        `Resized canvas. Video source is ${Math.floor(
          this.video.nativeElement.videoWidth
        )} x ${Math.floor(
          this.video.nativeElement.videoHeight
        )}\nCanvas container is ${Math.floor(
          this.canvasContainer.clientWidth
        )} x ${Math.floor(this.canvasContainer.clientHeight)}`
      );
    this._videoToCanvasContainerFactor = this.fitRectangle(
      this.video.nativeElement.videoWidth,
      this.video.nativeElement.videoHeight,
      this.canvasContainer.clientWidth,
      this.canvasContainer.clientHeight
    );
    // Resize canvas
    this.canvas.nativeElement.width =
      this.video.nativeElement.videoWidth * this._videoToCanvasContainerFactor;
    this.canvas.nativeElement.height =
      this.video.nativeElement.videoHeight * this._videoToCanvasContainerFactor;
    this.canvas.nativeElement.style.width =
      this.video.nativeElement.videoWidth * this._videoToCanvasContainerFactor +
      "px";
    if (this.verbose)
      console.log(
        `Canvas resized to ${Math.floor(
          this.canvas.nativeElement.width
        )} x ${Math.floor(this.canvas.nativeElement.height)}`
      );
    // this.canvas.nativeElement.height = this.canvas.parentElement.clientHeight;
    // this.canvas.nativeElement.width = this.canvas.clientWidth;
  }

  renderVideo() {
    this.ctx.fillStyle = "black";

    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    this.ctx.setTransform(
      this._canvasTransform[0],
      this._canvasTransform[1],
      this._canvasTransform[2],
      this._canvasTransform[3],
      this._canvasTransform[4],
      this._canvasTransform[5]
    );

    // Calculate letterboxing
    // TODO: REVIEW
    const canvasAR =
      this.canvasContainer.clientHeight / this.canvasContainer.clientWidth;
    const videoAR =
      this.video.nativeElement.videoHeight /
      this.video.nativeElement.videoWidth;
    const cAR =
      this.canvas.nativeElement.height / this.canvas.nativeElement.width;

    const letterBoxOffsets = {
      x:
        videoAR > canvasAR
          ? this.canvasContainer.clientWidth -
            this.canvasContainer.clientHeight / canvasAR / cAR
          : 0,
      y:
        videoAR < canvasAR
          ? this.canvasContainer.clientHeight -
            (this.canvasContainer.clientWidth / cAR) * canvasAR
          : 0,
    };

    letterBoxOffsets.x = 0;
    // letterBoxOffsets.y = 0;

    this.ctx.drawImage(
      this.video.nativeElement,
      this.video.nativeElement.videoWidth * ((1 - 1 / this.zoom) / 2),
      this.video.nativeElement.videoHeight * ((1 - 1 / this.zoom) / 2),
      this.video.nativeElement.videoWidth * (1 / this.zoom),
      this.video.nativeElement.videoHeight * (1 / this.zoom),
      letterBoxOffsets.x / 2,
      this.isMobile ? 0 : letterBoxOffsets.y / 2, // REVIEW: ¯\_(ツ)_/¯
      this.canvas.nativeElement.width - letterBoxOffsets.x,
      this.canvas.nativeElement.height -
        (this.isMobile ? letterBoxOffsets.y / 2 : letterBoxOffsets.y)
    );
    this.frameDrawn$.next();
    this.ctx.restore();
  }

  fitRectangle(win: number, hin: number, wout: number, hout: number): number {
    this.ratio = hin / win;
    if (this.verbose) console.log("RATIO", this.ratio);
    const factor = Math.min(wout / win, hout / hin);
    return factor;
  }

  stop() {
    this.video.nativeElement.pause();
    this.video.nativeElement.currentTime = 0;
  }

  progressBarClicked(event) {
    if (event.target.id === "tracker") return;
    const time =
      event.offsetX !== undefined
        ? (event.offsetX / this.trackBar.nativeElement.clientWidth) *
          this.video.nativeElement.duration
        : this.video.nativeElement.currentTime +
          (event.delta.x / this.trackBar.nativeElement.clientWidth) *
            this.video.nativeElement.duration;
    this._seek(time);
  }

  private _seek(time: number) {
    if (time > 0 && time < this.video.nativeElement.duration) {
      this.video.nativeElement.currentTime = time;
    }
  }

  playPause(event?) {
    this.video.nativeElement.paused
      ? this.video.nativeElement.play()
      : this.video.nativeElement.pause();
  }

  private _seekBy(seconds: number | any) {
    switch (typeof seconds) {
      case "number":
        this.video.nativeElement.currentTime += seconds;
        break;
      case "object":
        this.video.nativeElement.currentTime -=
          Math.sign(seconds.deltaY) * (seconds.shiftKey ? 0.03 : 0.5);
        break;
    }
  }

  updateProgress(event) {
    this.videoCurrentTime = this.video.nativeElement.currentTime;
    this.tracker.nativeElement.style.left = `${
      (100 * this.videoCurrentTime) / this.video.nativeElement.duration
    }%`;
  }

  gotoBookmark(event, bookmark: IVideoROIFrame) {
    event?.stopPropagation();
    if (this._roiRemovalActive) {
      console.log("Removing roi...");
      this.bookmarkAltClicked.emit(bookmark);
    } else {
      this.video.nativeElement.currentTime = bookmark.t1 || 0;
      this.video.nativeElement.pause();
    }
  }

  get showVideoSelectionOverlay$() {
    return this._showVideoSelectionOverlay.asObservable();
  }
  toggleVideoSelectionOverlay(
    show: boolean = !this._showVideoSelectionOverlay.value
  ) {
    if (this.isMobile) {
      if (show) {
        this._dialog.open(this.videoSelectionTemplate, {
          hasBackdrop: true,
        });
      } else if (this._dialog.openDialogs.length) {
        const dialog = this._dialog.openDialogs[0];
        dialog.close();
      }
    }
    this._showVideoSelectionOverlay.next(show);
  }

  onMetadataLoaded() {
    this.isLoading = false;
  }

  onLoadStart() {
    this.isLoading = true;
  }

  onCanPlayThrough() {
    this.isLoading = false; // Set loading to false when the video can play
    this.videoBar.nativeElement.hidden = false; // Unhide the video bar
  }
  get isMobile(): boolean {
    return this._platform.IOS || this._platform.ANDROID;
  }

  get numItems() {
    return this._assets.length;
  }
}
