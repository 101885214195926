import { createAction, props } from "@ngrx/store";
import {
  CreateAnalysisDetails,
  RoiActionDetails,
  UpdateAnalysisDetails,
  SetROIActionDetails,
  RefStripDetails,
  CreateFindingsActionDetails,
  VideoRoiActionDetails,
} from "./history.reducer";

export const clearHistoryState = createAction("[ROIS] Clear history");
export const historyActionError = createAction(
  "[HISTORY] Action Error",
  props<{ error: string }>()
);
export const registerAction = createAction(
  "[HISTORY] Register action",
  props<{
    operationType: string;
    details:
      | CreateAnalysisDetails[]
      | RefStripDetails
      | RoiActionDetails
      | VideoRoiActionDetails
      | UpdateAnalysisDetails
      | SetROIActionDetails
      | CreateFindingsActionDetails;
  }>()
);
