import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import {
  DurationPipeModule,
  PreprocessFilePipeModule,
} from "@telespot/shared/util";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OpenseadragonComponent } from "./components/openseadragon/openseadragon.component";
import { OsdRoiComponent } from "./components/osd-roi/osd-roi.component";
import { OsdZoomControllerComponent } from "./components/osd-zoom-controller/osd-zoom-controller.component";
import { ResizableBoxComponent } from "./components/resizable-box/resizable-box.component";
import { SampleReferenceStripComponent } from "./components/sample-reference-strip/sample-reference-strip.component";
import { VideoViewerComponent } from "./components/video-viewer/video-viewer.component";
import { OsdOverlayDirective } from "./directives/osd-overlay.directive";
import { OsdRoisDirective } from "./directives/osd-rois.directive";
import { VideoRoisDirective } from "./directives/video-rois.directive";
import { MaskViewerComponent } from "./components/mask-viewer/mask-viewer.component";
import { MetricScaleReferenceComponent, RulerComponent } from "./components";

@NgModule({
  declarations: [
    VideoViewerComponent,
    SampleReferenceStripComponent,
    MetricScaleReferenceComponent,
    ResizableBoxComponent,
    OpenseadragonComponent,
    OsdRoisDirective,
    VideoRoisDirective,
    OsdOverlayDirective,
    OsdZoomControllerComponent,
    OsdRoiComponent,
    MaskViewerComponent,
    RulerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DurationPipeModule,
    MatTooltipModule,
    PreprocessFilePipeModule,
    MatBadgeModule,
    MatSliderModule,
    ScrollingModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    SampleReferenceStripComponent,
    MetricScaleReferenceComponent,
    OpenseadragonComponent,
    OsdRoisDirective,
    VideoRoisDirective,
    ResizableBoxComponent,
    VideoViewerComponent,
    OsdOverlayDirective,
    OsdZoomControllerComponent,
    OsdRoiComponent,
    MaskViewerComponent,
    RulerComponent,
  ],
  providers: [],
})
export class ViewersFeatureModule {}
