import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  checkTaskTypes,
  clearAllLabels,
  mosaicView,
  RoiService,
  SampleAnalysisService,
  taskTypesFromSelectedLabels,
  toogleMosaicView,
} from "@telespot/analysis-refactor/data-access";

import {
  IAssetROIWithModels,
  StepTask,
  TRoiSelectionType,
} from "@telespot/sdk";
import {
  MasksPluginService,
  OtherTools,
  TOsdActiveAction,
  TSegmentationAction,
  ViewerService,
  TMosaicAction,
} from "@telespot/shared/viewers/data-access";
import { Observable, Subject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

type ViewerTool = TOsdActiveAction;

@Component({
  selector: "ts-viewer-tools",
  templateUrl: "./viewer-tools.component.html",
  styleUrls: ["./viewer-tools.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerToolsComponent implements OnInit, OnDestroy {
  public readonly selectedROIs$: Observable<IAssetROIWithModels[]> =
    this._roiService.selectedRois$;
  private _destroy$ = new Subject();

  //review activetool
  public readonly activeTool$: Observable<ViewerTool> =
    this._viewerService.activeViewerMode$;
  public readonly activeDrawingMode$: Observable<TRoiSelectionType> =
    this._viewerService.activeDrawingMode$;
  public readonly activeSegmentationMode$: Observable<TSegmentationAction> =
    this._maskService.activeSegmentationMode$;
  public readonly isViewMode$ = this._sampleAnalysisService.isViewMode$;

  public readonly taskTypesFromSelectedLabels$ = this._store.select(
    taskTypesFromSelectedLabels
  );

  @Input() mobile = false;
  @Input() enableTagging: boolean;
  @Input() mosaicMode: boolean;

  public availableDrawingModes$ = this.taskTypesFromSelectedLabels$.pipe(
    map((types) => {
      const drawingModes = [
        {
          mode: TRoiSelectionType.center,
          enabled:
            types.length === 0 ? true : types.includes(StepTask.POSITION),
        },
        {
          mode: TRoiSelectionType.boundingBox,
          enabled:
            types.length === 0
              ? true
              : types.includes(StepTask.ROIDETECTION) ||
                types.includes(StepTask.ROITRACKING),
        },
      ];

      if (types.length === 1)
        this.toggleDrawingMode(drawingModes.find((m) => m.enabled).mode);

      return drawingModes;
    })
  );

  public readonly availableSegmentationModes = [
    {
      mode: TSegmentationAction.edit,
      enabled: true,
    },
    {
      mode: TSegmentationAction.paint,
      enabled: true,
    },
  ];

  public readonly availableTools = [
    TOsdActiveAction.idle,
    TOsdActiveAction.drawing,
    TOsdActiveAction.selecting,
    TOsdActiveAction.removing,
    OtherTools.layers,
  ];

  public readonly availableModes = [
    TMosaicAction.grid,
    TMosaicAction.filter,
    TMosaicAction.filter_column,
  ];

  public readonly mosaicView$ = this._store.select(mosaicView);
  public selectedIndex$: Observable<number>;

  public readonly availableTools$ = this._store
    .select(checkTaskTypes)
    .pipe(
      map(({ hasROITasks, hasSegmTasks }) => [
        TOsdActiveAction.idle,
        ...(hasROITasks
          ? [TOsdActiveAction.drawing, TOsdActiveAction.selecting]
          : []),
        ...(hasSegmTasks ? [OtherTools.layers] : []),
      ])
    );

  public readonly selectedToolIndex$ = combineLatest([
    this.activeTool$,
    this.availableTools$,
  ]).pipe(
    map(([activeTool, availableTools]) => {
      if (availableTools.includes(activeTool)) {
        return availableTools.indexOf(activeTool);
      } else {
        this.toggleMode(TOsdActiveAction.idle);
      }
    })
  );

  constructor(
    private _store: Store,
    private _viewerService: ViewerService,
    private _roiService: RoiService,
    private _sampleAnalysisService: SampleAnalysisService,
    private _maskService: MasksPluginService
  ) {}

  public toggleDrawingMode(mode: TRoiSelectionType): void {
    this._viewerService.toggleDrawingMode(mode);
  }

  public toggleSegmentationMode(mode: TSegmentationAction): void {
    this._maskService.toggleSegmentationMode(mode);
  }

  public toggleMode(mode: ViewerTool): void {
    switch (mode) {
      case TOsdActiveAction.drawing:
        this._viewerService.toggleViewerMode(mode as TOsdActiveAction);
        this._roiService.selectROIs(null, true);
        break;
      case TOsdActiveAction.selecting:
        // this._store.dispatch(clearAllLabels());
        this._viewerService.toggleViewerMode(mode as TOsdActiveAction);
        break;
      default:
        this._viewerService.toggleViewerMode(mode as TOsdActiveAction);
    }
  }

  public removeSelectedROIs(): void {
    this._roiService.removeSelectedROIs();
  }

  public acceptAIRois(rois: IAssetROIWithModels[]): void {
    this._roiService.acceptAIROIs(rois);
  }

  public someAIROIs(rois: IAssetROIWithModels[]): boolean {
    return rois.some((roi) => roi.isAIresult);
  }

  public getOpacity(opactity: number): string {
    return `${Math.floor(opactity * 100)}%`;
  }

  public disabledTool(tool: string): boolean {
    return tool === "selecting"
      ? true
      : this.enableTagging
      ? tool === "idle"
        ? true
        : false
      : tool === "drawing"
      ? true
      : false;
  }

  public toggleMosaicMode(mode: TMosaicAction): void {
    this._store.dispatch(toogleMosaicView({ mosaicView: mode }));
  }

  ngOnInit(): void {
    this.selectedIndex$ = this.mosaicView$.pipe(
      map((mosaicViewMode) =>
        this.availableModes.indexOf(mosaicViewMode as TMosaicAction)
      )
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
