import { createReducer, on } from "@ngrx/store";
import { ISampleItem } from "../models/i-sample-item";
import * as ViewerCtxActions from "./viewer-context.actions";
import { initialState, ViewerContextState } from "./viewer-context.state";

export const viewerCtxReducer = createReducer<ViewerContextState>(
  initialState,
  on(ViewerCtxActions.setSelectedSample, (state, { selectedSample }) => ({
    ...state,
    selectedSample,
  })),
  on(ViewerCtxActions.setNumAssets, (state, { numAssets }) => ({
    ...state,
    numAssets,
  })),
  on(ViewerCtxActions.clearViewerCtxState, (state) => ({
    ...initialState,
    mosaics: state.mosaics,
  })),
  on(ViewerCtxActions.assetLoaded, (state, { asset }) => {
    const refStripItemsUpdated = state.refStripItems.map((i) => {
      if (i.assetId === asset.id) {
        const setWidthAndHeight = !i?.width || !i?.height;

        if (setWidthAndHeight) {
          return {
            ...i,
            width: asset?.data?.width,
            height: asset?.data?.height,
          };
        }
      }
      return i;
    });

    return {
      ...state,
      selectedAsset: asset,
      refStripItems: refStripItemsUpdated,
    };
  }),
  on(ViewerCtxActions.roisActionError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    ViewerCtxActions.sampleAnalysisStateFetched,
    (state, { analysisState }) => {
      const availableAnalysts = [...(state?.availableAnalysts || [])];
      const currentSelectedAnalystIndex = availableAnalysts.findIndex(
        (_analyst) => _analyst?.selected
      );
      const analystToBeSelectedIndex = availableAnalysts.findIndex(
        (_analyst) => _analyst?.analysisState?.id === analysisState?.id
      );
      if (currentSelectedAnalystIndex > -1) {
        availableAnalysts[currentSelectedAnalystIndex] = {
          ...availableAnalysts[currentSelectedAnalystIndex],
          selected: false,
        };
      }
      if (analystToBeSelectedIndex > -1) {
        availableAnalysts[analystToBeSelectedIndex] = {
          ...availableAnalysts[analystToBeSelectedIndex],
          selected: true,
        };
      }

      const refStripItems = [
        ...(state?.refStripItems.map((i) => ({
          ...i,
          fetched: false,
          favAnalysis: analysisState?.favAssets?.includes(i.assetId) ?? false,
        })) || []),
      ];

      return {
        ...state,
        sampleAnalysisState: analysisState,
        availableAnalysts,
        refStripItems,
      };
    }
  ),
  on(ViewerCtxActions.caseAnalysisStateFetched, (state, { analysisState }) => {
    return { ...state, caseAnalysisState: analysisState };
  }),
  on(ViewerCtxActions.loadRefStripElements, (state, { refStripItems }) => {
    const refStripItemsUpdated = [
      ...(refStripItems.map((i) => ({
        ...i,
        favAnalysis:
          state.sampleAnalysisState?.favAssets?.includes(i.assetId) ?? false,
      })) || []),
    ];

    state.selectedAsset
      ? refStripItemsUpdated.find((item) =>
          item.assetId === state.selectedAsset.id
            ? (item.viewed = true)
            : (item.viewed = false)
        )
      : (refStripItemsUpdated[0].viewed = true);

    const allRefStripItems = [
      ...(state?.refStripItems || []),
      ...(refStripItemsUpdated || []),
    ];

    return {
      ...state,
      refStripItems: allRefStripItems.sort(
        (a, b) => a.assetIndex - b.assetIndex
      ),
      selectedAssetIndex: state.selectedAsset
        ? refStripItemsUpdated.find(
            (item) => item.assetId === state?.selectedAsset.id
          )?.assetIndex ?? state.selectedAssetIndex
        : 0,
    };
  }),
  on(
    ViewerCtxActions.refStripLoaded,
    (state, { refStripDataResponse, itemsRequested, assetIds, isAuthUser }) => {
      const refItemsUpdated = state?.refStripItems.map((item) => {
        const elementUpdate = refStripDataResponse.find(
          (elem) => elem?.assetId === item?.assetId
        );

        return elementUpdate
          ? {
              ...item,
              ...elementUpdate,
              fetched: true,
              ...(isAuthUser && {
                analyzed:
                  assetIds?.includes(item.assetId) || elementUpdate.analyzed,
              }),
            }
          : itemsRequested?.includes(item.assetId)
          ? {
              ...item,
              reviewed: false,
              fetched: true,
              analyzed: assetIds?.includes(item.assetId),
              largeObjects: false,
            }
          : { ...item };
      });
      return {
        ...state,
        refStripItems: refItemsUpdated,
      };
    }
  ),
  on(ViewerCtxActions.markItemAsViewed, (state, { assetId }) => {
    const refItemsUpdated = [...(state?.refStripItems || [])];
    if (assetId) {
      const index = refItemsUpdated.findIndex(
        (item) => item?.assetId === assetId
      );
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        viewed: true,
      };
    }
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(ViewerCtxActions.setFavAnalysis, (state, { assetId, analysisState }) => {
    const refItemsUpdated = [...(state?.refStripItems || [])];
    if (assetId) {
      const index = refItemsUpdated.findIndex(
        (item) => item?.assetId === assetId
      );
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        favAnalysis: analysisState?.favAssets?.includes(assetId),
      };
    }
    return {
      ...state,
      refStripItems: refItemsUpdated,
      sampleAnalysisState: analysisState,
    };
  }),
  on(ViewerCtxActions.markAssetAsReviewed, (state, { assetIds }) => {
    const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
    const indexArray = refItemsUpdated
      .filter((item) => assetIds.includes(item?.assetId))
      .map((item) => item.assetIndex);
    indexArray.map((index) => {
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        reviewed: true,
      };
    });
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(ViewerCtxActions.markAssetAsAnalyzed, (state, { assetId }) => {
    const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
    const indexArray = refItemsUpdated
      .filter((item) => assetId === item?.assetId)
      .map((item) => item.assetIndex);
    indexArray.map((index) => {
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        analyzed: true,
      };
    });
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),
  on(
    ViewerCtxActions.discardMarkAsAnalyzed,
    (state, { assetId, analysisStatesId }) => {
      if (analysisStatesId !== state?.sampleAnalysisState?.id)
        return { ...state };
      const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
      const indexArray = refItemsUpdated
        .filter((item) => assetId === item?.assetId)
        .map((item) => item.assetIndex);
      indexArray.map((index) => {
        refItemsUpdated[index] = {
          ...refItemsUpdated[index],
          analyzed: false,
        };
      });
      return {
        ...state,
        refStripItems: refItemsUpdated,
      };
    }
  ),
  on(ViewerCtxActions.assetIndexLoaded, (state, { index }) => ({
    ...state,
    selectedAssetIndex: index,
  })),
  on(ViewerCtxActions.bookmarkAsset, (state, { analysisState }) => ({
    ...state,
    sampleAnalysisState: analysisState,
  })),
  on(
    ViewerCtxActions.setAvailableAnalysts,
    (state, { analysisStates, currentUserId }) => {
      const availableAnalysts = (analysisStates ?? []).map((analysisState) => ({
        analysisState,
        selected: analysisState?.id === state?.sampleAnalysisState?.id,
        isCurrentUser: analysisState.user?.id === currentUserId,
        isAI: !!analysisState.algorithm,
      }));
      return {
        ...state,
        availableAnalysts: availableAnalysts,
      };
    }
  ),
  on(
    ViewerCtxActions.loadCaseSampleRef,
    (
      state,
      {
        nextCase,
        previousCase,
        nextSample,
        previousSample,
        nextCaseSample,
        prevCaseSample,
      }
    ) => ({
      ...state,
      nextCase: {
        caseId: nextCase?.id ?? null,
        sampleId: nextCaseSample?.id ?? null,
      },
      previousCase: {
        caseId: previousCase?.id ?? null,
        sampleId: prevCaseSample?.id ?? null,
      },
      nextSample: nextSample?.id ?? null,
      previousSample: previousSample?.id ?? null,
    })
  ),
  on(ViewerCtxActions.updateAssetFilters, (state, { filter }) => {
    return {
      ...state,
      assetFilters: {
        ...state?.assetFilters,
        [filter]: !state.assetFilters[filter],
      },
    };
  }),
  on(ViewerCtxActions.setViewerType, (state, { viewerType }) => ({
    ...state,
    viewerType,
  })),
  on(ViewerCtxActions.toogleAnalysisMode, (state, { mode }) => {
    const isMosaic =
      mode === ViewerCtxActions.AnalysisMode.NORMAL ? false : true;
    return { ...state, mosaics: { ...state?.mosaics, mosaicMode: isMosaic } };
  }),
  on(ViewerCtxActions.toogleMosaicView, (state, { mosaicView }) => {
    return {
      ...state,
      mosaics: {
        ...state?.mosaics,
        mosaicView: mosaicView,
      },
    };
  }),
  on(ViewerCtxActions.addMosaicSelectedLabel, (state, { label }) => {
    return {
      ...state,
      mosaics: {
        ...state?.mosaics,
        selectedLabels: [...(state?.mosaics?.selectedLabels || []), label],
      },
    };
  }),
  on(ViewerCtxActions.removeMosaicSelectedLabel, (state, { labels }) => {
    return {
      ...state,
      mosaics: {
        ...state?.mosaics,
        selectedLabels: state?.mosaics?.selectedLabels?.filter(
          (item) => !labels.includes(item)
        ),
      },
    };
  }),
  on(ViewerCtxActions.resetMosaicSelectedLabel, (state) => {
    return {
      ...state,
      mosaics: {
        ...state?.mosaics,
        selectedLabels: [],
      },
    };
  }),

  // REVIEW: ad-hoc for big cells filter
  on(ViewerCtxActions.markAssetAsLargeCells, (state, { assetId, markAs }) => {
    const refItemsUpdated: ISampleItem[] = [...(state?.refStripItems || [])];
    const indexArray = refItemsUpdated
      .filter((item) => assetId === item?.assetId)
      .map((item) => item.assetIndex);
    indexArray.map((index) => {
      refItemsUpdated[index] = {
        ...refItemsUpdated[index],
        largeObjects: markAs,
      };
    });
    return {
      ...state,
      refStripItems: refItemsUpdated,
    };
  }),

  on(ViewerCtxActions.updateMultipleFiltersFlag, (state, { active }) => {
    return { ...state, multipleFiltersEnabled: active };
  }),
  on(ViewerCtxActions.updateAcquisitionData, (state, { key, value }) => {
    const selectedAsset = state.selectedAsset;
    selectedAsset.data = { ...selectedAsset.data, [key]: value };
    return { ...state, selectedAsset, assetDataSynced: false };
  }),
  on(ViewerCtxActions.assetDataDiscarded, (state, { data }) => {
    const selectedAsset = state.selectedAsset;
    selectedAsset.data = { ...data };
    return { ...state, selectedAsset, assetDataSynced: true };
  }),
  on(ViewerCtxActions.assetDataSaved, (state) => {
    return { ...state, assetDataSynced: true };
  })
);
