import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
  catchError,
  debounceTime,
  delay,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";

import * as AnalysisActions from "./analysis.actions";
import { EMPTY, forkJoin, from, of } from "rxjs";
import { Action, Store } from "@ngrx/store";
import { getROIsInsideRegion, IAnalysis } from "./analysis.reducer";

import * as AnalysisSelectors from "./analysis.selectors";
import { AnalysisService } from "../../services/analysis-service/analysis.service";
import { FindingService } from "../../services/finding-service/finding.service";
import * as ProtocolSelectors from "../protocol/protocol.selectors";
import * as ViewerCtxSelectors from "../../+state/viewer-context.selectors";
import * as ViewerCtxActions from "../../+state/viewer-context.actions";
import * as SyncActions from "../../state/sync/sync.actions";
import {
  activeAnalysisIds,
  analystToExtractCrops,
  findingInfoFromAnalyst,
  getActiveSegmentationFinding,
  missingCropsFromSyncedFinding,
  nextToken,
  pendingAssets,
  roisWithoutCropsFilteredByLabel,
  selectActiveCount,
  selectAllSampleVisibleROIS,
  selectAssetROIsFromUser,
  selectCurrentAnalyst,
  selectMicroActiveROIS,
  unlabeledRois,
} from "../interfeature.selectors";

import { SampleAnalysisService } from "../../services/sample-analysis/sample-analysis.service";

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MaskViewerService } from "@telespot/shared/viewers/data-access";
import { MosaicService } from "@telespot/web-core";
import {
  SyncedItemType,
  deleteSyncedItem,
  loadSyncedItems,
  nextLabelId,
  nextSyncedFinding,
  selectSyncedItemsByType,
} from "../sync";
import { findingsSyncedSaved } from "./analysis.actions";
import { FindingMapper } from "../../services/finding-service/finding.mapper";
import { RoiMapper } from "../../services/roi-service/roi.mapper";

@Injectable()
export class AnalysisEffects {
  private readonly throttleTimeInMs = 150;
  private readonly pageSize = 100;

  loadAssetAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadAssetAnalysis),
      withLatestFrom(
        this.store$.select(AnalysisSelectors.selectAnalysis),
        this.store$.select(ProtocolSelectors.selectTaskGroupsIds),
        this.store$.select(ViewerCtxSelectors.mosaicMode),
        this.store$.select(ProtocolSelectors.selectLabelsId),
        this.store$.select(ProtocolSelectors.customLabelsAvailable)
      ),
      mergeMap(
        ([
          { assetId, sampleId, createdBy },
          cache,
          pipelineIds,
          isMosaicMode,
          labelIds,
          allowCustomLabels,
        ]) => {
          const cachedAnalysis = cache.filter(
            (analysis: IAnalysis) =>
              !analysis.isSampleAnalysis &&
              analysis.assetId === assetId &&
              analysis.createdBy.className === createdBy.className &&
              analysis.createdBy.objectId === createdBy.objectId
          );

          const fetchMissingFindings =
            cachedAnalysis.filter((a) => a.fetchedPartially).length !== 0;

          if (
            (cachedAnalysis.length > 0 && !fetchMissingFindings) ||
            isMosaicMode
          )
            return of(AnalysisActions.updateLoading({ loading: false }));

          const infoFetched = fetchMissingFindings
            ? this.analysisService.loadMissingFindings(
                cachedAnalysis.map((a) => a.id),
                labelIds,
                allowCustomLabels
              )
            : this.analysisService.loadAssetAnalysis({
                assetIds: [assetId],
                sampleId,
                createdBy,
                pipelineIds,
                labelIds,
                allowCustomLabels,
              });

          return infoFetched.pipe(
            mergeMap(({ analysis, findings, rois, videoRois }) => [
              AnalysisActions.assetAnalysisLoaded({
                analysis,
                findings,
                rois,
                videoRois,
              }),
              AnalysisActions.updateAssetsProcessed({
                assetsProcessed: [assetId],
              }),
            ]),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadAssetAnalysis]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadSampleAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadSampleAnalysis),
      withLatestFrom(
        this.store$.select(AnalysisSelectors.selectAnalysis),
        this.store$.select(ProtocolSelectors.selectTaskGroupsIds)
      ),
      mergeMap(([{ sampleId, createdBy }, cache, pipelineIds]) => {
        const cachedAnalysis = cache.filter(
          (analysis: IAnalysis) =>
            analysis.isSampleAnalysis &&
            analysis.createdBy.className === createdBy.className &&
            analysis.createdBy.objectId === createdBy.objectId &&
            analysis.sampleId === sampleId
        );

        if (cachedAnalysis.length > 0) return EMPTY;

        return this.analysisService
          .loadSampleAnalysis({ sampleId, createdBy, pipelineIds })
          .pipe(
            map(({ analysis, findings }) =>
              AnalysisActions.sampleAnalysisLoaded({ analysis, findings })
            ),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadSampleAnalysis]: ${error.message}`,
                })
              )
            )
          );
      })
    )
  );

  preSyncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.preSyncAnalysis),
      withLatestFrom(
        this.store$.select(ProtocolSelectors.selectHasSegmentationTasks),
        this.store$.select(getActiveSegmentationFinding)
      ),
      mergeMap(([action, hasSegmentationTasks, activeSegmentationFinding]) => {
        if (hasSegmentationTasks && activeSegmentationFinding !== undefined) {
          this._maskViewerService.saveMaskOnLocalStorage.emit({
            save: true,
            id: activeSegmentationFinding.id,
          });
          return EMPTY;
        }
        return of(AnalysisActions.syncAnalysis());
      })
    )
  );

  syncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.syncAnalysis),
      withLatestFrom(
        this.store$.select(AnalysisSelectors.selectUnsyncedAnalysis),
        this.store$.select(AnalysisSelectors.selectMode),
        this.store$.select(selectCurrentAnalyst)
      ),
      mergeMap(([_, analysis, mode, currentUser]) =>
        from(this.analysisService.saveAnalysis(analysis, mode)).pipe(
          map((idChanges) => {
            return AnalysisActions.analysisSynced({ idChanges, currentUser });
          }),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[syncAnalysis]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  syncFindings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.analysisSynced),
      withLatestFrom(
        this.store$.select(AnalysisSelectors.selectUnsyncedFindings),
        this.store$.select(selectAllSampleVisibleROIS),
        this.store$.select(AnalysisSelectors.selectVideoRois),
        this.store$.select(AnalysisSelectors.selectMode),
        this.store$.select(ProtocolSelectors.selectHasSegmentationTasks),
        this.store$.select(ProtocolSelectors.selectTaskGroups),
        this.store$.select(ViewerCtxSelectors.selectRefStripElements)
      ),
      mergeMap(
        ([
          { idChanges },
          findings,
          rois,
          videoRois,
          mode,
          hasSegmentationTasks,
          protocols,
          assetsInfo,
        ]) =>
          from(
            this.findingService.saveFindings(
              findings,
              idChanges,
              rois,
              videoRois,
              mode,
              hasSegmentationTasks,
              protocols,
              assetsInfo
            )
          ).pipe(
            map((idChanges) => {
              this._sampleAnalysisService.giveUserFeedback("Changes saved");
              return AnalysisActions.findingsSynced({
                idChanges,
                visibleRois: rois,
              });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[syncFindings]: ${error.message}`,
                })
              )
            )
          )
      )
    )
  );

  selectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.selectROIsFromRegion),
      withLatestFrom(this.store$.select(AnalysisSelectors.selectRois)),
      mergeMap(([selectROIsReq, rois]) => {
        if (!selectROIsReq.bounds) return EMPTY;
        return of({
          rois: getROIsInsideRegion(
            rois,
            selectROIsReq.bounds,
            selectROIsReq.activeAnalysisIds
          ),
          replace: false,
        }).pipe(
          map(({ rois, replace }) =>
            AnalysisActions.setSelectedROIs({ rois, replace })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[selectROIsFromRegion]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  copyAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.copyAnalysis),
      mergeMap(
        ({ authUser }) =>
          of(authUser).pipe(
            withLatestFrom(
              this.store$.select(activeAnalysisIds),
              this.store$.select(selectMicroActiveROIS),
              this.store$.select(selectAssetROIsFromUser(authUser))
            )
          ),
        (authUser, latestStoreData) => latestStoreData
      ),
      mergeMap(([authUser, activeAnalysisIds, newROIs, oldROIs]) => {
        return of({ authUser, activeAnalysisIds, newROIs, oldROIs });
      }),
      mergeMap(({ authUser, activeAnalysisIds, newROIs, oldROIs }) => [
        AnalysisActions.analysisCopied({
          authUser,
          activeAnalysisIds,
          newROIs,
        }),
        AnalysisActions.setReviewCounters({ newROIs, oldROIs, authUser }),
      ]),
      catchError((error) =>
        of(AnalysisActions.analysisActionError({ error: error.message }))
      )
    )
  );

  deselectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ViewerCtxActions.sampleAnalysisStateFetched,
        ViewerCtxActions.setAsset
      ),
      mergeMap((_) => {
        return of(_).pipe(
          map((_) =>
            AnalysisActions.setSelectedROIs({ rois: [], replace: true })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[sampleAnalysisStateFetched, setAsset]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  createSegmAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.createSegmAnalysis),
      withLatestFrom(
        this.store$.select(ViewerCtxSelectors.analysisState),
        this.store$.select(ViewerCtxSelectors.selectAsset),
        this.store$.select(ViewerCtxSelectors.selectActiveSample),
        this.store$.select(ProtocolSelectors.selectedLabels)
      ),
      mergeMap(([action, analysisState, asset, sample, selectedLabels]) => {
        return of({
          createdBy: analysisState.user.toPointer(),
          assetId: asset?.id,
          sampleId: sample?.id,
          pipelineId: selectedLabels[0].pipelineId,
          taskId: selectedLabels[0].taskId,
        });
      }),
      map((payload) => AnalysisActions.segmAnalysisCreated(payload))
    )
  );

  clearStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.exitAnalysis),
      tap(() => {
        Object.keys(localStorage)
          .filter(
            (item) => item.startsWith("localMask/") || item.startsWith("crop_")
          )
          .forEach((item) => localStorage.removeItem(item));
      }),
      map(() => {
        return { type: "LocalStorageCleared" } as Action;
      })
    )
  );

  discardAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.discardAnalysis),
      withLatestFrom(
        this.store$.select(AnalysisSelectors.selectAnalysis),
        this.store$.select(AnalysisSelectors.selectFindings),
        this.store$.select(ProtocolSelectors.selectHasSegmentationTasks)
      ),
      mergeMap(
        ([
          { analysisDiscarded, hasROI },
          allAnalysis,
          allFindings,
          hasSegmentationTasks,
        ]) => {
          const analysisToDiscard = analysisDiscarded
            .map((analysisInfo) => {
              return allAnalysis.find(
                (a) =>
                  a.pipelineId === analysisInfo.pipelineId &&
                  a?.createdBy.objectId === analysisInfo?.createdBy.objectId &&
                  a?.assetId === analysisInfo?.assetId &&
                  a?.sampleId === analysisInfo?.sampleId
              );
            })
            .filter((a) => a);

          const analysisDiscardedIds = analysisToDiscard.map((a) => a?.id);

          const findingsDiscardedIds = allFindings
            .filter((f) =>
              analysisDiscardedIds.some((an) => f.analysisId.includes(an))
            )
            .map((f) => f?.id);

          if (hasSegmentationTasks) {
            findingsDiscardedIds.map((id) =>
              localStorage.removeItem(`localMask/${id}`)
            );
          }

          return of({ analysisDiscardedIds, hasROI }).pipe(
            map(({ analysisDiscardedIds, hasROI }) => {
              return AnalysisActions.analysisDiscarded({
                analysisDiscardedIds,
                findingsDiscardedIds,
                hasROI,
              });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[discardAnalysis]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  getInfoForMosaicView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadMosaic),
      withLatestFrom(
        this.store$.select(pendingAssets),
        this.store$.select(ViewerCtxSelectors.selectActiveSample),
        this.store$.select(ProtocolSelectors.selectTaskGroupsIds),
        this.store$.select(ProtocolSelectors.selectLabelsId),
        this.store$.select(ProtocolSelectors.customLabelsAvailable),
        this.store$.select(analystToExtractCrops),
        this.store$.select(selectCurrentAnalyst)
      ),
      mergeMap(
        ([
          _,
          assetIds,
          sample,
          pipelineIds,
          labelIds,
          allowCustomLabels,
          createdBy,
          currAnalyst,
        ]) => {
          const reviewing = createdBy.objectId !== currAnalyst.objectId;

          const info = this.analysisService.loadAssetAnalysis({
            assetIds,
            sampleId: sample.id,
            createdBy,
            pipelineIds,
            labelIds,
            allowCustomLabels,
          });

          return info.pipe(
            mergeMap(({ analysis, findings, rois }) => {
              if (reviewing) {
                const { modifiedAnalysis, modifiedFindings, modifiedRois } =
                  this.analysisService.markAsUnSyncedAndNew(
                    analysis,
                    findings,
                    rois,
                    currAnalyst
                  );

                analysis = modifiedAnalysis;
                findings = modifiedFindings;
                rois = modifiedRois;
              }

              return [
                AnalysisActions.assetAnalysisLoaded({
                  analysis,
                  findings,
                  rois,
                  videoRois: [],
                }),
                AnalysisActions.fetchCropsFromSynced(),
                AnalysisActions.updateAssetsProcessed({
                  assetsProcessed: assetIds,
                }),
              ];
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[getInfoForMosaicView]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadSyncedFindingsCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.fetchCropsFromSynced),
      withLatestFrom(
        this.store$.select(nextSyncedFinding),
        this.store$.select(missingCropsFromSyncedFinding),
        this.store$.select(selectCurrentAnalyst),
        this.store$.select(ViewerCtxSelectors.selectRefStripElements),
        this.store$.select(selectSyncedItemsByType(SyncedItemType.FINDING))
      ),
      mergeMap(
        ([
          _,
          nextSyncedFinding,
          stillMissingCrops,
          analyst,
          refstripItems,
          syncedFindings,
        ]) => {
          if (!nextSyncedFinding) {
            return of(
              AnalysisActions.loadInitialCrops({
                limit: this.pageSize,
                fetchedCount: 0,
              })
            );
          }

          if (!stillMissingCrops)
            return from([
              deleteSyncedItem({ findingIds: [nextSyncedFinding.id] }),
              AnalysisActions.fetchCropsFromSynced(),
            ]).pipe(delay(500));

          return from(
            this._mosaicService.getRoisFromFinding(
              nextSyncedFinding.id,
              100,
              nextSyncedFinding.nextToken
            )
          ).pipe(
            mergeMap(({ items, nextToken }) => {
              const changes = items.map((item) => {
                const refItem = refstripItems.find(
                  (elem) => elem.assetId === item.assetId
                );

                const coords = FindingMapper.denormalizeRoi(refItem, item);

                const id = RoiMapper.getId(coords, analyst.objectId);

                return {
                  id,
                  changes: { cropFileName: item.id },
                };
              });

              const processing = this.findingService.stillProcessing(
                syncedFindings,
                items
              );

              if (processing) return [AnalysisActions.fetchCropsFromSynced()];

              return from([
                SyncActions.updateSyncedItemToken({
                  id: nextSyncedFinding.id,
                  nextToken,
                }),
                AnalysisActions.mosaicCropsLoaded({
                  roiChanges: changes,
                }),
                AnalysisActions.fetchCropsFromSynced(),
              ]).pipe(delay(500));
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadSyncedFindingsCrops$]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  saveMosaicCropInStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.mosaicCropsLoaded),
      mergeMap((action) => {
        const roisInfo = action.roiChanges;

        const filenames = roisInfo.map((r) => r.changes.cropFileName);
        const cropBlobs$ = this.findingService.fetchCropBlobs(filenames);
        return forkJoin(cropBlobs$).pipe(
          mergeMap((cropBlobs) => {
            cropBlobs.map((blob, index) => {
              localStorage.setItem(
                `crop_${roisInfo[index].id}`,
                URL.createObjectURL(blob)
              );
            });
            return EMPTY;
          }),
          catchError((error) => {
            console.error("Error fetching or saving crop blob:", error);
            return of(
              AnalysisActions.analysisActionError({
                error: null,
              })
            );
          })
        );
      })
    )
  );

  dragCrop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.dragCrop),
      mergeMap(({ roiId, previousLabels, newLabels }) =>
        of(roiId).pipe(
          withLatestFrom(
            this.store$.select(AnalysisSelectors.getRoiById(roiId))
          ),
          map(([_, roi]) => ({ previousLabels, newLabels, roi }))
        )
      ),
      mergeMap(({ previousLabels, newLabels, roi }) => {
        if (!roi) return EMPTY;
        //Review: This suppose this roi has only labels for one findingId.
        const labelsUpdated = roi.labels.map((label) => {
          const updatedLabels = newLabels.length ? { ...label.labels } : {};

          previousLabels.forEach(
            (prevLabel) => delete updatedLabels[prevLabel]
          );

          newLabels.forEach((newLabel) => {
            updatedLabels[newLabel] = 1; // 'User' score
          });

          return {
            ...label,
            labels: updatedLabels,
          };
        });

        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  changeCropLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.changeCropLabels),
      mergeMap(({ roiId, findingId, newLabel }) =>
        of(roiId).pipe(
          withLatestFrom(
            this.store$.select(AnalysisSelectors.getRoiById(roiId))
          ),
          map(([_, roi]) => ({ findingId, newLabel, roi }))
        )
      ),
      mergeMap(({ findingId, newLabel, roi }) => {
        if (!roi) return EMPTY;
        const labelsUpdated = roi.labels.map((label) => {
          if (label.findingId !== findingId) return label;
          const labelExists = Object.keys(label.labels).find(
            (l) => l === newLabel
          );
          const newLabels = labelExists
            ? Object.keys(label.labels).reduce((acc, l) => {
                if (l === newLabel) return acc;
                return { ...acc, [l]: label.labels[l] };
              }, {})
            : { ...label.labels, [newLabel]: 1 };

          return {
            ...label,
            labels: newLabels,
          };
        });
        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  addLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.addLabel),
      mergeMap(({ roiId, newLabel }) =>
        of(roiId).pipe(
          withLatestFrom(
            this.store$.select(AnalysisSelectors.getRoiById(roiId))
          ),
          map(([_, roi]) => ({ newLabel, roi }))
        )
      ),
      mergeMap(({ newLabel, roi }) => {
        if (!roi) return;
        const labelsUpdated = roi.labels.map((label) => {
          const { ...labels } = label.labels;
          return {
            ...label,
            labels: {
              ...labels,
              [newLabel]: 1,
            },
          };
        });
        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  deleteUnlabeledRois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.toogleAnalysisMode),
      withLatestFrom(this.store$.select(unlabeledRois)),
      mergeMap(([action, unlabeledRois]) => {
        return of(AnalysisActions.deleteUnlabeledRois({ unlabeledRois }));
      })
    )
  );

  setLoadingMosaic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewerCtxActions.toogleAnalysisMode),
      mergeMap(({ mode }) => {
        if (mode === "normal") return EMPTY;
        return of(AnalysisActions.setMosaicLoading({ loading: true }));
      })
    )
  );

  syncedItemsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSyncedItems),
      map(({ syncedItems }) => findingsSyncedSaved({ syncedItems }))
    )
  );

  initialLoadForCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadInitialCrops),
      // throttleTime(this.throttleTimeInMs),
      mergeMap(
        (action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.select(nextToken(undefined)),
              this.store$.select(ProtocolSelectors.selectTaskGroupsIds),
              this.store$.select(analystToExtractCrops),
              this.store$.select(ViewerCtxSelectors.selectActiveSample),
              this.store$.select(findingInfoFromAnalyst),
              this.store$.select(ViewerCtxSelectors.selectRefStripElements),
              this.store$.select(roisWithoutCropsFilteredByLabel(undefined)),
              this.store$.select(nextLabelId),
              this.store$.select(selectActiveCount),
              this.store$.select(ProtocolSelectors.labelsWithThresholds)
            )
          ),
        (labelId, data) => data
      ),
      switchMap(
        ([
          { limit, fetchedCount },
          nextToken,
          pipelineIds,
          analyst,
          sample,
          findingInfo,
          refstripItems,
          stateRois,
          nextLabelId,
          activeCount,
          labelsWithThresholds,
        ]) => {
          if (!nextLabelId)
            return of(AnalysisActions.setMosaicLoading({ loading: false }));
          if (stateRois.length === 0 && fetchedCount !== 0)
            return from([
              SyncActions.updateCropPagination({
                nextToken: stateRois.length === 0 ? undefined : nextToken,
                labelId: nextLabelId,
              }),
              AnalysisActions.loadInitialCrops({
                limit,
                fetchedCount,
              }),
            ]);

          const maxLimit =
            activeCount?.totalCount < limit ? activeCount?.totalCount : limit;

          const thrdisp = labelsWithThresholds[nextLabelId];

          return from(
            this._mosaicService.getRoisFromSample(
              sample.id,
              analyst.objectId,
              this.pageSize,
              nextLabelId,
              pipelineIds,
              nextToken
            )
          ).pipe(
            map(({ items, nextToken }) => {
              const changes = this.findingService.extractRoisChanges(
                items,
                findingInfo,
                stateRois,
                refstripItems,
                nextLabelId,
                thrdisp
              );
              fetchedCount = fetchedCount + changes.length;
              return { changes, nextToken, fetchedCount, maxLimit };
            }),
            mergeMap(({ changes, nextToken }) => {
              const actions = [];
              actions.push(
                SyncActions.updateCropPagination({
                  nextToken:
                    changes?.length === 0 || !nextToken ? undefined : nextToken,
                  labelId: nextLabelId,
                }),
                AnalysisActions.mosaicCropsLoaded({
                  roiChanges: changes,
                })
              );

              if (fetchedCount < maxLimit) {
                actions.push(
                  AnalysisActions.loadInitialCrops({
                    limit: maxLimit,
                    fetchedCount,
                  })
                );
              } else {
                actions.push(
                  AnalysisActions.setMosaicLoading({ loading: false })
                );
              }

              return actions.length ? from(actions).pipe(delay(500)) : EMPTY;
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[initialLoadForCrops$]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadMoreCrops$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadMore),
      debounceTime(this.throttleTimeInMs),
      mergeMap(
        (action) =>
          of(action).pipe(
            withLatestFrom(
              this.store$.select(nextToken(action.labelId)),
              this.store$.select(ProtocolSelectors.selectTaskGroupsIds),
              this.store$.select(analystToExtractCrops),
              this.store$.select(ViewerCtxSelectors.selectActiveSample),
              this.store$.select(findingInfoFromAnalyst),
              this.store$.select(ViewerCtxSelectors.selectRefStripElements),
              this.store$.select(
                roisWithoutCropsFilteredByLabel(action.labelId)
              ),
              this.store$.select(nextLabelId),
              this.store$.select(ProtocolSelectors.labelsWithThresholds)
            )
          ),
        (labelId, data) => data
      ),
      switchMap(
        ([
          { labelId },
          nextToken,
          pipelineIds,
          analyst,
          sample,
          findingInfo,
          refstripItems,
          stateRois,
          nextLabelId,
          labelsWithThresholds,
        ]) => {
          const label = labelId ?? nextLabelId;
          if (stateRois.length === 0) {
            const actions = [];
            actions.push(
              AnalysisActions.mosaicCropsLoaded({
                roiChanges: [],
              })
            );
            if (label) {
              actions.push(
                SyncActions.updateCropPagination({
                  nextToken: undefined,
                  labelId: label,
                })
              );
            }
            return actions;
          }

          const thrdisp = labelsWithThresholds[label];

          return from(
            this._mosaicService.getRoisFromSample(
              sample.id,
              analyst.objectId,
              this.pageSize,
              label,
              pipelineIds,
              nextToken
            )
          ).pipe(
            map(({ items, nextToken }) => {
              const changes = this.findingService.extractRoisChanges(
                items,
                findingInfo,
                stateRois,
                refstripItems,
                label,
                thrdisp
              );
              return { changes, nextToken };
            }),
            mergeMap(({ changes, nextToken }) => [
              SyncActions.updateCropPagination({
                nextToken:
                  changes?.length === 0 || !nextToken ? undefined : nextToken,
                labelId: label,
              }),
              AnalysisActions.mosaicCropsLoaded({
                roiChanges: changes,
              }),
            ]),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadMoreCrops$]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private analysisService: AnalysisService,
    private findingService: FindingService,
    private _sampleAnalysisService: SampleAnalysisService,
    private _maskViewerService: MaskViewerService,
    private _mosaicService: MosaicService
  ) {}
}
