<div
  *ngIf="sample$ | async as sample; else loading"
  class="full-height black-bg"
>
  <ng-container *ngTemplateOutlet="viewerTemplate"></ng-container>

  <!-- Viewer -->
  <ng-template #viewerTemplate>
    <div
      class="position-relative full-width full-height"
      #viewerDiv
      *ngIf="{
        layout: layout$ | async,
        asset: selectedAsset$ | async,
        analysisContext: analysisContext$ | async,
        isViewMode: isViewMode$ | async,
        viewerMode: activeViewerMode$ | async,
        availableROItasks: availableROItasks$ | async,
        availableSegmtasks: availableSegmTasks$ | async,
        nextCase: nextCase$ | async,
        previousCase: previousCase$ | async,
        nextSample: nextSample$ | async,
        previousSample: previousSample$ | async,
        viewerType: viewerType$ | async,
        mosaicMode: mosaicMode$ | async,
        protocol: protocol$ | async
      } as state"
    >
      <!-- MICROSCOPE IMAGE VIEWER -->
      <ng-container *ngIf="state.viewerType === 'image' && !state.mosaicMode">
        <!-- OPENSEADRAGON LAYER -->
        <ts-openseadragon
          #osdViewer="osdViewer"
          appOsdRois
          #appOsdRois="osdROIs"
          [enableTagging]="state.availableROItasks"
          [action]="state.viewerMode"
          [src]="sample"
        >
          <!-- SIDE TOOLS (ZOOM) -->
          <ng-container *ngIf="state.layout === 'web'">
            <div class="side-tools-container flex-column align-content-between">
              <div class="zoom-controllers-div">
                <ng-container *ngIf="showFabs"> </ng-container>
                <ts-osd-zoom-controller
                  class="justify-self-center"
                  [osd]="osdViewer"
                ></ts-osd-zoom-controller>
              </div>
              <ng-container
                *ngTemplateOutlet="
                  fabToolsTemplate;
                  context: { $implicit: osdViewer }
                "
              ></ng-container>
            </div>
          </ng-container>

          <ng-template #viewerExtras>
            <ng-container
              *ngIf="state.availableSegmtasks"
              tsMasksPlugin
              #masksPlugin="masksPlugin"
            >
              <ts-mask-viewer
                [osd]="osdViewer"
                [opacity]="masksPlugin.opacity$ | async"
                [strokeWidth]="masksPlugin.strokeWidth$ | async"
              ></ts-mask-viewer>
            </ng-container>
            <ng-container
              *ngFor="
                let roi of appOsdRois.visibleRois$ | async;
                let i = index;
                trackBy: trackById
              "
            >
              <ts-osd-roi [roi]="roi" [index]="i"></ts-osd-roi>
            </ng-container>
          </ng-template>
          <ng-container
            *ngIf="hspan$ | async"
            tsMetricScalePlugin
            #metricScalePlugin="metricScalePlugin"
          >
            <ng-container *ngIf="osdViewer.viewerReady$ | async">
              <ts-ruler-layer
                [osd]="osdViewer"
                [rulerSizeUm]="metricScalePlugin.rulerSize$ | async"
                [rulerOption]="metricScalePlugin.rulerIcon$ | async"
                [hspan]="hspan$ | async"
              ></ts-ruler-layer>
              <div
                class="metric-reference bg-white"
                [class.mobile]="state.layout === 'mobile'"
              >
                <ts-metric-scale-reference
                  [osd]="osdViewer"
                  [hspan]="hspan$ | async"
                ></ts-metric-scale-reference>
              </div>
            </ng-container>
          </ng-container>
        </ts-openseadragon>
        <!-- BOTTOM STRIP -->
        <div
          style="
            position: absolute;
            bottom: 0px;
            width: 100%;
            max-height: 130px;
            z-index: 1;
          "
        >
          <ts-asset-filters></ts-asset-filters>
          <ts-sample-reference-strip
            data-cy="reference-strip"
          ></ts-sample-reference-strip>
        </div>
      </ng-container>
      <!-- MOSAIC IMAGE VIEWER -->
      <ng-container *ngIf="state.viewerType === 'image' && state.mosaicMode">
        <ng-container *ngIf="mosaicView$ | async as view">
          <div class="analysis-mosaic-container">
            <ts-sample-analysis-mosaics
              *ngIf="view === 'grid'"
            ></ts-sample-analysis-mosaics>
            <ts-sample-analysis-mosaics-selector
              *ngIf="view === 'filter'"
              [protocol]="state.protocol"
              [useGlobalState]="true"
            ></ts-sample-analysis-mosaics-selector>
            <div
              *ngIf="view === 'filter_column'"
              class="analysis-mosaic-container-column"
            >
              <ts-sample-analysis-mosaics-selector
                [protocol]="state.protocol"
                [useGlobalState]="true"
              ></ts-sample-analysis-mosaics-selector>
              <ts-sample-analysis-mosaics-selector
                [protocol]="state.protocol"
                [useGlobalState]="false"
              ></ts-sample-analysis-mosaics-selector>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- MICROSCOPE VIDEO VIEWER -->
      <ng-container *ngIf="state.viewerType === 'video'">
        <ts-video-viewer
          [src]="sample"
          appVideoRois
          #videoROIs="videoROIs"
          (itemIndexChanged)="selectAssetWithIndex($event, undefined)"
          #videoViewer
          (resizeEvent)="videoBarResized()"
        >
          <!-- Progress bar content, i.e. bookmarks -->

          <div #scrollableWrapper class="scrollable-timeline-wrapper">
            <div class="scrollable-timeline-container">
              <div class="scrollable-timeline-content">
                <ng-container *ngIf="videoROIs.rois$ | async as rois">
                  <div *ngFor="let roi of rois" class="roi-track" id="roiTrack">
                    <div
                      class="roi-bar"
                      [attr.data-id]="roi.id"
                      [style.left]="videoViewer.relativeTime(roi.t1) + '%'"
                      [style.width]="
                        videoViewer.relativeTime(roi.t2 - roi.t1) + '%'
                      "
                      [style.background-color]="videoROIs.getColor(roi, true)"
                      [class.deletable]="state.viewerMode === 'removing'"
                      (click)="
                        videoViewer.gotoBookmark($event, roi);
                        videoROIs.selectVideoROI(roi, true)
                      "
                      [style.box-shadow]="
                        roi.selected
                          ? 'rgb(0, 153, 187) 0px 0px 5px 2px'
                          : 'none'
                      "
                      cdkDrag
                      cdkDragLockAxis="x"
                      (mousedown)="videoROIs.onMouseDown($event)"
                      (cdkDragStarted)="videoROIs.startDraggingROI(roi, $event)"
                      (cdkDragMoved)="videoROIs.onDragMove(roi, $event)"
                      (cdkDragEnded)="
                        videoROIs.processBookMarkDrag(roi, $event)
                      "
                      [matTooltip]="videoROIs.getModelsList(roi)"
                    ></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ts-video-viewer>
      </ng-container>
      <ng-container
        *ngIf="state.layout === 'mobile'"
        [ngTemplateOutlet]="mobileAnalysisPanelTemplate"
      ></ng-container>
      <!-- ANALYSIS ACTIONS AND PANELS CONTAINER -->
      <ng-container>
        <div
          class="analysis-panel-and-actions-container"
          [class.mobile]="state.layout === 'mobile'"
        >
          <!-- PROTOCOL PANEL CONTAINER MICROSCOPE -->
          <div
            class="micro-analysis-panel-container"
            *ngIf="state.layout === 'web' && !state.mosaicMode"
          >
            <ts-sample-analysis-panel
              (sendSecondOpinion)="saveAndExit()"
              #analysisPanel
            >
              <div
                analysisPanelHeader
                *ngIf="state.analysisContext === 'asset'"
              >
                <div
                  class="options-panel__item flex-row align-items-center"
                  [matTooltip]="'tooltip.display_favourite_models' | translate"
                >
                  <span class="flex-grow-1">
                    {{ "label.display_favourite_models" | translate }}
                  </span>
                  <mat-slide-toggle
                    id="toggle-pinned-models"
                    [labelPosition]="'before'"
                    style="margin-left: auto"
                    [checked]="showPinned$ | async"
                    (change)="togglePinnedModels()"
                    data-cy="toggle-pinned-models"
                  >
                  </mat-slide-toggle>
                </div>
                <div
                  *ngIf="state.availableROItasks"
                  class="options-panel__item flex-row align-items-center"
                  [matTooltip]="'tooltip.multi_model_rois' | translate"
                >
                  <span class="flex-grow-1">
                    {{ "label.multi_model_rois" | translate }}
                  </span>
                  <mat-slide-toggle
                    id="toggle-multi-model-rois"
                    [labelPosition]="'before'"
                    style="margin-left: auto"
                    [checked]="multiModelSelection$ | async"
                    (change)="toggleMultiModelSelection()"
                    data-cy="toggle-multi-models"
                  >
                  </mat-slide-toggle>
                </div>
                <div
                  class="options-panel__item flex-row align-items-center"
                  *ngIf="stitchingScans"
                >
                  <span class="flex-grow-1">
                    {{ "Number of images: " + stitchingScans | translate }}
                  </span>
                </div>
              </div>
              <div
                analysisPanelActions
                style="text-align: center"
                *ngIfRole="[
                  '!analyst',
                  '!analystmanager',
                  '!technician',
                  '!admin',
                  '!technicianmanager'
                ]"
              >
                <button
                  style="margin-bottom: 24px"
                  class="btn btn-primary"
                  type="button"
                  (click)="saveAndExit()"
                  data-cy="submit-second-opinion"
                >
                  <i class="ri-send-plane-2-line"></i>
                  {{ "send opinion" | translate | uppercase }}
                </button>
              </div>
            </ts-sample-analysis-panel>
            <ts-roi-model-stats></ts-roi-model-stats>
          </div>
          <!-- PROTOCOL PANEL CONTAINER MOSAICS -->
          <div
            class="mosaic-analysis-panel-container"
            *ngIf="state.layout === 'web' && state.mosaicMode"
          >
            <ts-sample-analysis-panel-mosaics
              [protocol]="state.protocol"
            ></ts-sample-analysis-panel-mosaics>
          </div>

          <!-- VIEWER TOOLS CONTAINER -->
          <ng-container *ngIf="!state.isViewMode && state.layout === 'web'">
            <ts-viewer-tools
              [enableTagging]="state.availableROItasks"
              [mosaicMode]="state.mosaicMode"
            ></ts-viewer-tools>
          </ng-container>
          <!-- SAMPLE ACTIONS CONTAINER -->
          <div
            class="sample-actions-and-progress-container"
            *ngIf="{ unsaved: hasUnsavedData$ | async } as actionsState"
          >
            <div
              id="mobile-tab-headers"
              class="flex-row flex-align-center"
              *ngIf="state.layout === 'mobile'"
            >
              <div class="flex-row">
                <button
                  class="toolbar-action btn float-left"
                  matTooltip="{{ 'button.close' | translate }}"
                  (click)="exit()"
                  [routerLink]="[]"
                  queryParamsHandling="merge"
                  *ngIfRole="[
                    'admin',
                    'analyst',
                    'analystmanager',
                    'technician'
                  ]"
                  data-cy="toolbar-exit"
                >
                  <i class="ri-close-fill"></i>
                </button>
                <button
                  class="toolbar-action btn caution float-left mx-3"
                  matTooltip="{{ 'button.save' | translate }}"
                  [routerLink]="['./']"
                  queryParamsHandling="merge"
                  [class.bg-warning]="actionsState.unsaved"
                  [class.text-white]="actionsState.unsaved"
                  (click)="saveAndExit()"
                  *ngIfRole="['admin', 'analyst', 'analystmanager']"
                  data-cy="toolbar-save-analysis"
                  tsAnalytics="save_sample_analysis"
                >
                  <i class="ri-save-line"></i>
                </button>
              </div>
              <div class="ml-auto">
                <button
                  class="toolbar-action btn float-right mx-3"
                  #btn
                  [matMenuTriggerFor]="menuHeaders"
                  data-cy="button-switch-analysis-context"
                >
                  <i
                    [ngClass]="{
                      'ri-image-line': state.analysisContext === 'asset',
                      'ri-grid-line': state.analysisContext === 'sample'
                    }"
                  ></i>
                </button>
                <mat-menu #menuHeaders="matMenu">
                  <a
                    mat-menu-item
                    class="toolbar-action auto flex-row align-items-center"
                    (click)="toggleAsset()"
                    role="button"
                    data-cy="button-switchto-asset-analysis"
                    tsAnalytics="switch_to_asset_analysis"
                  >
                    <i
                      class="ri-image-line"
                      [class.text-primary]="state.analysisContext === 'asset'"
                    ></i>
                    <span>{{
                      "core.analysis_image" | translate | uppercase
                    }}</span>
                  </a>
                  <a
                    mat-menu-item
                    class="toolbar-action auto flex-row align-items-center"
                    (click)="toggleAsset()"
                    role="button"
                    data-cy="button-switchto-sample-analysis"
                    tsAnalytics="switch_to_sample_analysis"
                  >
                    <i
                      class="ri-grid-line"
                      [class.text-primary]="state.analysisContext === 'sample'"
                    ></i>
                    <span>{{
                      "core.analysis_sample" | translate | uppercase
                    }}</span>
                  </a>
                  <span style="flex-grow: 1"></span>
                </mat-menu>
              </div>
            </div>
            <ts-sample-analysis-tools
              (exitEvent)="exit()"
              (save)="saveAndExit()"
              (download)="
                downloadFile(sample.methodType.assetType.type === 'image')
              "
              (secondOpinion)="requestSecondOpinion()"
              (updateOrderIA)="updateOrder($event)"
              (toggleMode)="toggleAnalysisMode($event)"
              [sample]="getSample()"
              [numAssets$]="numAssets$"
              [mosaicMode]="state.mosaicMode"
              [hspan]="hspan$ | async"
            ></ts-sample-analysis-tools>

            <div
              *ngIf="state.layout === 'web' && !state.mosaicMode"
              id="progress-container"
            >
              <span class="sample-reference">{{
                sample.case.workspace.name
              }}</span>
              <div class="sample-reference">
                <button
                  type="button"
                  class="nextPrevious-btn"
                  style="
                    pointer-events: all;
                    padding: 0;
                    height: 27px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  [disabled]="
                    !state.previousCase?.caseId || !state.previousCase?.sampleId
                  "
                  (click)="
                    goToPreviousCase(
                      state.previousCase.caseId,
                      state.previousCase.sampleId
                    )
                  "
                >
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <span class="text-ellipsis">{{ sample.case.name }}</span>
                <button
                  type="button"
                  class="nextPrevious-btn"
                  style="
                    pointer-events: all;
                    padding: 0;
                    height: 27px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  [disabled]="
                    !state.nextCase?.caseId || !state.nextCase?.sampleId
                  "
                  (click)="
                    goToNextCase(state.nextCase.caseId, state.nextCase.sampleId)
                  "
                >
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
              <div class="sample-reference">
                <button
                  type="button"
                  class="nextPrevious-btn"
                  style="
                    pointer-events: all;
                    padding: 0;
                    height: 27px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  [disabled]="!state.previousSample"
                  (click)="goToPreviousSample(state.previousSample)"
                >
                  <i class="ri-arrow-left-s-line"></i>
                </button>
                <span class="text-ellipsis">{{ sample.name }}</span>
                <button
                  type="button"
                  class="nextPrevious-btn"
                  style="
                    pointer-events: all;
                    padding: 0;
                    height: 27px;
                    width: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  [disabled]="!state.nextSample"
                  (click)="goToNextSample(state.nextSample)"
                >
                  <i class="ri-arrow-right-s-line"></i>
                </button>
              </div>
              <div class="progressbar-container">
                <div
                  class="progressbar-fill"
                  [style.width]="sampleAnalysisProgress + '%'"
                ></div>
              </div>
            </div>
            <!-- ROI MULTI-SELECTION PANEL-->
            <ng-container *ngIf="!state.mosaicMode">
              <ts-roi-selection-panel></ts-roi-selection-panel>
            </ng-container>
            <ng-container *ngIf="state.layout === 'web'">
              <ng-container *ngIf="reviewedAnalysis$ | async as reviewedState">
                <ng-container *ngIf="switchingAnalystPanelVisible">
                  <div
                    *ngIf="
                      switchingAnalystPanelVisible && {
                        reviewedState: reviewedState,
                        viewingReviewed: viewingReviewedAnalyst$ | async,
                        selectedAsset: selectedAsset$ | async,
                        copyAllowed: copyAllowed$ | async,
                        hasActiveAssetAnalysis: hasActiveAssetAnalysis$ | async,
                        hasUnsavedData: hasUnsavedData$ | async
                      } as state
                    "
                    class="analyst-switching-panel bg-white p-2"
                    style="pointer-events: all"
                    data-cy="review-analyst-selector"
                  >
                    <div class="flex-row justify-content-between">
                      <mat-radio-group
                        class="flex-column justify-content-around"
                        [ngModel]="state.viewingReviewed"
                      >
                        <mat-radio-button
                          [value]="true"
                          data-cy="radio-review-theirs"
                          [checked]="state.viewingReviewed"
                          [disabled]="false"
                          (click)="switchActiveAnalysis(state.reviewedState)"
                          class="no-margin text-ellipsis"
                          style="
                            flex-basis: 50%;
                            text-overflow: ellipsis;
                            width: 170px;
                          "
                          [matTooltip]="state.reviewedState?.user?.username"
                          >{{
                            state.reviewedState?.user?.username ??
                              "AI: " + state.reviewedState?.algorithm?.name
                          }}</mat-radio-button
                        >
                        <mat-radio-button
                          [value]="false"
                          data-cy="radio-review-mine"
                          [checked]="!state.viewingReviewed"
                          [routerLink]="[]"
                          (click)="switchToReviewAnalysis()"
                          class="no-margin text-ellipsis"
                          style="flex-basis: 50%"
                          >{{
                            "label.my_analysis" | translate
                          }}</mat-radio-button
                        >
                      </mat-radio-group>
                      <div
                        class="flex-row align-items-start"
                        [matTooltip]="
                          !state.hasActiveAssetAnalysis
                            ? ('tooltip.user_has_no_analysis'
                              | translate
                                : {
                                    user:
                                      state.reviewedState?.user?.username ??
                                      state.reviewedState?.algorithm?.name
                                  })
                            : ('tooltip.analysis_already_copied' | translate)
                        "
                        [matTooltipDisabled]="
                          state.copyAllowed || !state.viewingReviewed
                        "
                      >
                        <button
                          mat-button
                          style="font-size: smaller"
                          class="flex-row align-items-center"
                          color="primary"
                          type="button"
                          [disabled]="
                            !state.viewingReviewed || !state.copyAllowed
                          "
                          (click)="
                            copyUserAnalysis(state.reviewedState, $event)
                          "
                          data-cy="review-analysis"
                        >
                          <i class="ri-file-copy-fill mx-1"></i>
                          {{ "button.copy" | translate | uppercase }}
                        </button>
                        <button
                          mat-icon-button
                          style="size: 16px"
                          matTooltip="{{ 'button.close' | translate }}"
                          (click)="
                            closeSwitchAnalystPanel(
                              !!state.reviewedState?.algorithm,
                              state.hasUnsavedData
                            )
                          "
                          data-cy="close-switching-analyst-panel"
                        >
                          <i class="ri-close-line"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <!-- FAB Tools -->
  <ng-template #fabToolsTemplate let-osd>
    <!-- Fab button strip -->
    <div
      id="buttons-bar"
      data-cy="FAB-tools"
      *ngIf="{
        fav: isFav$ | async,
        bookmarked: bookmarked$ | async,
        selectedAsset: selectedAsset$ | async,
        context: analysisContext$ | async,
        reviewing: viewingReviewedAnalyst$ | async
      } as FABState"
    >
      <ng-container class="tools" #tools *ngIf="showFabs">
        <button
          mat-mini-fab
          color="white"
          [class.hidden]="!showFabs"
          (click)="osd.center()"
          [matTooltip]="'tooltip.center_view' | translate"
        >
          <i class="ri-aspect-ratio-line"></i>
        </button>
        <button
          mat-mini-fab
          color="white"
          [class.hidden]="!showFabs"
          (click)="toggleFavouriteAnalysis()"
          [matTooltip]="
            (FABState.fav
              ? 'label.marked_fav_analysis'
              : 'label.mark_fav_analysis'
            ) | translate
          "
          *ngIf="FABState.context === 'asset' && !FABState.reviewing"
        >
          <i
            id="heart-icon"
            [class.ri-heart-3-fill]="FABState.fav"
            [class.ri-heart-3-line]="!FABState.fav"
          ></i>
        </button>
        <button
          *ngIf="FABState.selectedAsset && !FABState.reviewing"
          mat-mini-fab
          color="white"
          [class.hidden]="!showFabs"
          (click)="toggleBookmark(FABState.selectedAsset)"
          [matTooltip]="
            (FABState.bookmarked
              ? 'tooltip.remove_bookmark'
              : 'tooltip.set_bookmark'
            ) | translate
          "
        >
          <i
            id="bookmark-icon"
            [class.ri-bookmark-fill]="FABState.bookmarked"
            [class.ri-bookmark-line]="!FABState.bookmarked"
          ></i>
        </button>
      </ng-container>
      <button
        mat-fab
        class="text-white"
        id="toggleButtons"
        (click)="showFabs = !showFabs"
      >
        <i class="ri-close-fill" [ngClass]="{ 'rotate-45': !showFabs }"></i>
      </button>
    </div>
  </ng-template>

  <!-- Responsive Analysis Panel -->
  <ng-template #mobileAnalysisPanelTemplate>
    <ts-mobile-analysis-panel
      [hidden]="(showMobileAnalysisPanel$ | async) === false"
      (onclose)="closeMobileAnalysisPanel()"
      (delete)="removeSelectedROIs()"
    ></ts-mobile-analysis-panel>
  </ng-template>
</div>

<!-- Loading Page -->
<ng-template #loading>
  <div *ngIf="{ mosaicMode: mosaicMode$ | async } as loadingState">
    <div *ngIf="!loadingState.mosaicMode; else loadingMosaics">
      <div
        class="full-height flex-column justify-content-center align-items-center"
        *ngIf="{ progress: loadingProgress$ | async } as loading"
      >
        <mat-progress-bar
          style="width: 50%"
          mode="indeterminate"
          [value]="loading.progress"
        ></mat-progress-bar>
        <div style="margin-top: 16px">
          {{ "label.loading" | translate }} ({{ loading.progress }}%)
        </div>
      </div>
    </div>

    <ng-template #loadingMosaics>
      <div
        class="full-height flex-column justify-content-center align-items-center"
        *ngIf="{ progress: mosaicLoadingProgress$ | async } as mosaicLoading"
      >
        <div style="margin-top: 16px">
          {{ "info.loading_mosaics" | translate }}
        </div>
        <mat-progress-bar
          style="margin-top: 16px; width: 50%"
          mode="indeterminate"
          [value]="mosaicLoading.progress"
        ></mat-progress-bar>
        <div style="margin-top: 16px">({{ mosaicLoading.progress }}%)</div>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #reviewAnalysisDialogTpl let-data>
  <div class="flex-row break-line">
    {{
      "label.copy_and_review"
        | translate
          : {
              username:
                data.analysisState?.user?.username ?? "core.ia" | translate
            }
    }}
  </div>
  <mat-checkbox
    color="primary"
    class="blue-border p-1"
    [(ngModel)]="noShowCopyDialog"
    >{{ "label.no_ask_again" | translate }}</mat-checkbox
  >
  <div matDialogActions class="flex-row justify-content-around">
    <button class="btn btn-outline-primary" [matDialogClose]="false">
      {{ "button.cancel" | translate | uppercase }}
    </button>
    <button class="btn btn-primary" [matDialogClose]="true">
      {{ "button.accept" | translate | uppercase }}
    </button>
  </div>
</ng-template>
