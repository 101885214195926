/* eslint-disable @angular-eslint/directive-selector */
import {
  ComponentFactoryResolver,
  Directive,
  Host,
  OnDestroy,
  OnInit,
  Renderer2,
  Self,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  IVideoROIFrame,
  RoiService,
  SampleAnalysisService,
} from "@telespot/analysis-refactor/data-access";
import { AssetUtils } from "@telespot/sdk";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { VideoViewerComponent } from "../components/video-viewer/video-viewer.component";
import { RoisDirective } from "./rois.base.directive";

enum VideoROIOperation {
  LEFT_RESIZE = "leftResize",
  RIGHT_RESIZE = "rightResize",
  SHIFT = "shift",
}
@Directive({
  selector: "ts-video-viewer:[appVideoRois]",
  exportAs: "videoROIs",
})
export class VideoRoisDirective
  extends RoisDirective<VideoViewerComponent>
  implements OnInit, OnDestroy
{
  protected _roiElements: IVideoROIFrame[] = [];
  private _destroy: Subject<void> = new Subject();
  public rois$ = this._roiService.visibleVideoROIs$;
  public operation: VideoROIOperation;
  protected tempT1;
  protected tempT2;
  protected initialDraggingPoint;
  protected decreasingSize = false;

  private getAllLabels(roi: IVideoROIFrame): string[] {
    return roi ? Object.keys(roi.labels) : [];
  }

  getColor(roi: IVideoROIFrame, opaque?: boolean): string {
    return this.getModelColor(this.getAllLabels(roi), opaque);
  }

  getModelsList(roi: IVideoROIFrame): string {
    return this.getLabelName(this.getAllLabels(roi) ?? []);
  }

  addROI(roi: Partial<IVideoROIFrame>): void {
    try {
      this._roiService.addVideoROIs(roi);
    } catch (err) {
      this.snackbar.open(err.message, null, { duration: 800 });
    }
  }

  constructor(
    @Host() @Self() protected viewerComponent: VideoViewerComponent,
    protected renderer: Renderer2,
    protected resolver: ComponentFactoryResolver,
    protected snackbar: MatSnackBar,
    protected _roiService: RoiService,
    protected _sampleAnalysisService: SampleAnalysisService,
    protected _viewerService: ViewerService
  ) {
    super(
      viewerComponent,
      renderer,
      resolver,
      snackbar,
      _roiService,
      _sampleAnalysisService
    );

    this.viewerComponent.bookmarkClicked
      .pipe(takeUntil(this._destroy))
      .subscribe((roi) => {
        // FIXNOW: viewer component should know about the selected Asset
        this.addROI(roi);
      });

    this.viewerComponent.bookmarkAltClicked
      .pipe(takeUntil(this._destroy))
      .subscribe(($event) => {
        this._roiService.removeVideoROIs([$event]);
      });
  }

  ngOnInit() {
    console.log(`appVideoRois attached`);
    this._setupEventHandlers();
    this.rois$
      .pipe(takeUntil(this._destroy))
      .subscribe((rois) => (this._roiElements = rois as IVideoROIFrame[]));
  }

  ngOnDestroy(): void {
    this._destroy.next();
  }

  protected _removeEventHandlers() {
    return;
  }

  protected _setupEventHandlers() {
    console.log(
      `Directive setting event handlers for host`,
      this.viewerComponent.canvas
    );

    this.viewerComponent.canvas.nativeElement.addEventListener(
      "mousedown",
      this._mouseDownHandler.bind(this)
    );
    this.viewerComponent.canvas.nativeElement.addEventListener(
      "mouseup",
      this._stopPan
    );

    this.viewerComponent.frameDrawn$
      .pipe(takeUntil(this._destroy))
      .subscribe((_) => {
        if (!this.viewerComponent.ctx) return;
        this._roiElements
          .filter(
            (roi) =>
              this.viewerComponent.videoCurrentTime >= roi.t1 &&
              this.viewerComponent.videoCurrentTime <= roi.t2
          )
          .forEach((roi) => {
            this.viewerComponent.ctx.strokeStyle = this.getModelColor(
              this.getAllLabels(roi),
              true
            );
            this.viewerComponent.ctx.strokeRect(
              roi.x,
              roi.y,
              roi?.w - 1,
              roi?.h - 1
            );

            this.viewerComponent.ctx.stroke();
          });

        if (this._tempROI) {
          this.viewerComponent.ctx.strokeStyle = "white";
          this.viewerComponent.ctx.strokeRect(
            this._tempROI.x,
            this._tempROI.y,
            this._tempROI.w,
            this._tempROI.h
          ),
            this.viewerComponent.ctx.stroke();
        }
      });
  }

  private _getImagePosition = (event) => {
    const tform = this.viewerComponent.canvasTransform;
    return {
      x: ((event.offsetX - tform[4]) * 1) / tform[0],
      y: ((event.offsetY - tform[5]) * 1) / tform[0],
    };
  };

  private _mouseDownHandler = (event) => {
    if (event.shiftKey) {
      this._viewerService.toggleViewerMode(TOsdActiveAction.drawing);
      // Initialize ROI
      const pos = this._getImagePosition(event);
      this._tempROI = {
        x: pos.x,
        y: pos.y,
        w: 0,
        h: 0,
        time: this.viewerComponent.video.nativeElement.currentTime,
      };
      this.viewerComponent.canvas.nativeElement.addEventListener(
        "mousemove",
        this._mouseMoveHandler
      );
      this.viewerComponent.video.nativeElement.pause();
      console.log(`ROI updated to ${AssetUtils.toString(this._tempROI)}`);
    }
  };

  private _mouseMoveHandler = (event) => {
    if (!this._tempROI?.x || !this._tempROI?.y) return;
    if (this.activeMode === TOsdActiveAction.drawing) {
      const pos = this._getImagePosition(event);

      this._tempROI.w = pos.x - this._tempROI.x;
      this._tempROI.h = pos.y - this._tempROI.y;
    }
  };

  private _stopPan = (event) => {
    if (this._tempROI) {
      AssetUtils.makeIntegerCoords(this._tempROI);
      const time2 = this.viewerComponent.video.nativeElement.currentTime;
      console.log(`ROI FINISHED: ${AssetUtils.toString(this._tempROI)}`);
      this.addROI({
        x: this._tempROI.x,
        y: this._tempROI.y,
        w: this._tempROI?.w,
        h: this._tempROI?.h,
        t1: this._tempROI.time,
      });
      this._tempROI = undefined;
    }
    this._viewerService.toggleViewerMode(TOsdActiveAction.idle);

    this.viewerComponent.canvas.nativeElement.removeEventListener(
      "mousemove",
      this._mouseMoveHandler
    );
  };

  onMouseDown(event: MouseEvent) {
    const roiTrackRect = this.viewerComponent.trackBar.nativeElement
      .querySelector("#roiTrack")
      .getBoundingClientRect();

    this.initialDraggingPoint = event.clientX - roiTrackRect.left;
  }

  startDraggingROI(roi: IVideoROIFrame, event) {
    const clientWidth = this.viewerComponent.trackBar.nativeElement.clientWidth;
    const resizeThreshold = 5;
    const relativeT1 =
      (roi.t1 * clientWidth) / this.viewerComponent.videoDuration;
    const relativeT2 =
      (roi.t2 * clientWidth) / this.viewerComponent.videoDuration;
    const t2ResizeLimit = relativeT2 - resizeThreshold;
    const t1ResizeLimit = relativeT1 + resizeThreshold;

    const isResizingLeft = this.initialDraggingPoint <= t1ResizeLimit;
    const isResizingRight = this.initialDraggingPoint >= t2ResizeLimit;

    const isShifting =
      this.initialDraggingPoint > t1ResizeLimit &&
      this.initialDraggingPoint < t2ResizeLimit;

    if (isResizingLeft) this.operation = VideoROIOperation.LEFT_RESIZE;
    if (isResizingRight) this.operation = VideoROIOperation.RIGHT_RESIZE;
    if (isShifting) this.operation = VideoROIOperation.SHIFT;
  }

  onDragMove(roi: IVideoROIFrame, event) {
    const dragElement =
      this.viewerComponent.trackBar.nativeElement.querySelector(
        `[data-id="${roi.id}"]`
      );

    //Prevent from default shifting
    dragElement.style.transform = "translate3d(0, 0, 0)";

    const initialOffSet =
      this.viewerComponent.trackBar.nativeElement.getBoundingClientRect().left;

    const currentCursorPos = event.event.clientX - initialOffSet;

    if (this.operation === VideoROIOperation.LEFT_RESIZE) {
      if (currentCursorPos > this.initialDraggingPoint) {
        // Cursor moved to the right, switch to right resize
        this.operation = VideoROIOperation.RIGHT_RESIZE;
        this.decreasingSize = true;
      }
    } else if (this.operation === VideoROIOperation.RIGHT_RESIZE) {
      if (currentCursorPos < this.initialDraggingPoint) {
        // Cursor moved to the left, switch to left resize
        this.operation = VideoROIOperation.LEFT_RESIZE;
        this.decreasingSize = true;
      }
    }
    this.handleResizeOrShift(roi, event);
  }

  private handleResizeOrShift(roi: IVideoROIFrame, event) {
    const timeDelta =
      (event.distance.x /
        this.viewerComponent.trackBar.nativeElement.clientWidth) *
      this.viewerComponent.videoDuration;

    if (this.operation === VideoROIOperation.LEFT_RESIZE) {
      const newT1 = roi.t1 + (this.decreasingSize ? 0 : timeDelta);
      const newT2 = roi.t2 + (this.decreasingSize ? timeDelta : 0);

      this.tempT1 = Math.min(newT1, newT2);
      this.tempT2 = Math.max(newT1, newT2);
    } else if (this.operation === VideoROIOperation.RIGHT_RESIZE) {
      const newT1 = roi.t1 + (this.decreasingSize ? timeDelta : 0);
      const newT2 = roi.t2 + (this.decreasingSize ? 0 : timeDelta);
      this.tempT1 = Math.min(newT1, newT2);
      this.tempT2 = Math.max(newT1, newT2);
    } else if (this.operation === VideoROIOperation.SHIFT) {
      this.tempT1 = roi.t1 + timeDelta;
      this.tempT2 = roi.t2 + timeDelta;
    }

    // Apply visual feedback: use the temporary values for t1 and t2
    this.updateROIVisual(roi, this.tempT1, this.tempT2);
  }

  processBookMarkDrag(roi: IVideoROIFrame, event) {
    const timeDelta =
      (event.distance.x /
        this.viewerComponent.trackBar.nativeElement.clientWidth) *
      this.viewerComponent.videoDuration;

    const maxtime = this.viewerComponent.videoDuration;

    if (this.operation === VideoROIOperation.LEFT_RESIZE) {
      let t1 = roi.t1;
      let t2 = roi.t2 + timeDelta;
      if (!this.decreasingSize) {
        t1 = roi.t1 + timeDelta;
        t2 = roi.t2;
      }
      this._roiService.updateROItime({
        roi,
        t1: Math.max(0, Math.min(t1, t2)),
        t2: Math.min(maxtime, Math.max(t1, t2)),
      });
    } else if (this.operation === VideoROIOperation.RIGHT_RESIZE) {
      let t1 = roi.t1 + timeDelta;
      let t2 = roi.t2;
      if (!this.decreasingSize) {
        t1 = roi.t1;
        t2 = roi.t2 + timeDelta;
      }
      this._roiService.updateROItime({
        roi,
        t1: Math.max(0, Math.min(t1, t2)),
        t2: Math.min(maxtime, Math.max(t1, t2)),
      });
    } else {
      const time = (roi.t1 || 0) + timeDelta;

      //Prevent from exceeding video duration

      const newtime = Math.min(maxtime - (roi.t2 - roi.t1), Math.max(0, time));

      this._roiService.updateROItime({
        roi,
        t1: newtime < 0 ? 0 : newtime, //Prevent negative values
        t2: undefined,
      });
      this.viewerComponent.gotoBookmark(null, { ...roi, t1: time });
    }

    this.decreasingSize = false;
  }

  updateROIVisual(roi: IVideoROIFrame, tempT1: number, tempT2: number) {
    const roiElement =
      this.viewerComponent.trackBar.nativeElement.querySelector(
        `[data-id="${roi.id}"]`
      );
    const clientWidth = this.viewerComponent.trackBar.nativeElement.clientWidth;

    const leftPosition =
      (tempT1 / this.viewerComponent.videoDuration) * clientWidth;
    const width =
      ((tempT2 - tempT1) / this.viewerComponent.videoDuration) * clientWidth;

    roiElement.style.left = `${leftPosition}px`;
    roiElement.style.width = `${width}px`;
  }

  selectVideoROI(rois: Partial<IVideoROIFrame>[], replace: boolean) {
    this._roiService.selectVideoROIs(rois, replace);
  }
}
