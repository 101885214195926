<div id="div1" class="flex-row">
  <video
    width="100%"
    height="82%"
    [controls]="false"
    id="video"
    #video
    preload="auto"
    seekable="true"
    [attr.webkit-playsinline]="isIOS"
    [attr.playsinline]="isIOS"
    crossorigin
    (timeupdate)="updateProgress($event)"
    (loadedmetadata)="onMetadataLoaded()"
    (loadstart)="onLoadStart()"
    (canplaythrough)="onCanPlayThrough()"
    [ngStyle]="{ display: useCanvas ? 'none' : 'visible' }"
  >
    <source id="videoSource" #source type="video/mp4" />
    Your browser does not support the video tag.
  </video>

  <div id="bigCanvasContainer">
    <canvas #canvas id="bigCanvas" [hidden]="!useCanvas"></canvas>
    <ng-content select="[videoOverlay]"></ng-content>
  </div>

  <ng-container *ngIf="enableDebug$ | async">
    <div
      *ngIfRole="['admin']"
      style="
        position: absolute;
        top: 200px;
        right: 30px;
        width: 200px;
        padding: 20px;
        color: white;
        background-color: gray;
      "
    >
      <ng-container *ngIf="canvasTransform$ | async as transform">
        Transform:
        <p>scale: {{ transform[0] }}</p>
        <p>dx: {{ transform[4] }}px</p>
        <p>dy: {{ transform[5] }}px</p>
      </ng-container>
    </div>
  </ng-container>

  <div class="video-selection-overlay-container" *ngIf="!isMobile">
    <ng-container
      [ngTemplateOutlet]="videoSelectionTemplate"
      *ngIf="showVideoSelectionOverlay$ | async"
    ></ng-container>
  </div>

  <ng-template #videoSelectionTemplate>
    <div id="video-selection-overlay" data-cy="video-selection-panel">
      <div class="flex-row justify-content-center">
        <button
          mat-icon-button
          class="btn btn-flat-primary"
          (click)="toggleVideoSelectionOverlay()"
        >
          <i class="ri-arrow-down-s-line"></i>
        </button>
      </div>
      <div class="flex-column video-selection-list">
        <div
          class="video-selection-list__source"
          *ngFor="let source of sources; let videoIndex = index"
          (click)="selectVideo(videoIndex); toggleVideoSelectionOverlay()"
          [class.selected]="videoIndex === selectedAssetId"
        >
          <img [src]="source.thumbnail | preprocess | async" alt="" />
          <div>Video {{ videoIndex + 1 }}</div>
        </div>
      </div>
    </div>
  </ng-template>

  <div id="videoBar" #videoBar class="d-flex" style="align-items: center">
    <!-- Playback Controls -->
    <button
      id="btn-stop"
      mat-icon-button
      class="btn"
      (click)="stop()"
      matTooltip="{{ 'button.stop' | translate }}"
    >
      <i class="ri-stop-fill"></i>
    </button>
    <button
      data-cy="video-play"
      id="btn-play"
      mat-icon-button
      class="btn"
      (click)="playPause()"
      matTooltip="{{ 'button.play_pause' | translate }}"
    >
      <i
        [ngClass]="{
          'ri-play-mini-fill': video.paused,
          'ri-pause-fill': !video.paused
        }"
      ></i>
    </button>

    <!-- Loop Control -->
    <button
      id="btn-loop"
      mat-icon-button
      class="btn"
      (click)="toggleLoop()"
      matTooltip="Toggle Loop"
      [ngClass]="{ 'active-loop': video.loop }"
    >
      <i class="ri-infinity-line"></i>
    </button>

    <!-- Dropdown for Speed Selection -->
    <div class="dropdown">
      <button
        id="btn-speed"
        mat-icon-button
        class="btn"
        matTooltip="Playback Speed"
        (click)="toggleSpeedDropdown()"
      >
        <i class="ri-speed-line"></i>
        <span>{{ playbackSpeed }}x</span>
      </button>
      <div class="dropdown-menu" [class.show]="speedDropdownOpen">
        <a class="dropdown-item" (click)="setPlaybackSpeed(0.5)">0.5x</a>
        <a class="dropdown-item" (click)="setPlaybackSpeed(1)">1x</a>
        <a class="dropdown-item" (click)="setPlaybackSpeed(1.5)">1.5x</a>
        <a class="dropdown-item" (click)="setPlaybackSpeed(2)">2x</a>
      </div>
    </div>

    <div id="videoBarDiv">
      <div class="reference-timeline">
        <div
          class="timeline-markers"
          style="margin-left: 10px; margin-right: 10px"
        >
          <span
            *ngFor="
              let marker of getTimelineMarkers(videoDuration);
              let i = index
            "
            [style.left]="marker.position + '%'"
          >
            | {{ marker.time }}
          </span>
        </div>
      </div>
      <div class="videoProgress" #trackbar (click)="progressBarClicked($event)">
        <div class="videoProgressLine">
          <div
            class="videoProgressLineFilled"
            [style.width]="relativeTime() + '%'"
          ></div>
        </div>
        <div
          class="videoTracker"
          id="tracker"
          #tracker
          [ngClass]="{ slow: slowSeek | async }"
        ></div>
        <ng-content selector="[progressBarItem]"></ng-content>
      </div>
    </div>
    <span id="label-time">
      <span
        class="label-video-index"
        *ngIf="(selectedVideoIndex$ | async) + 1 as index"
        [matTooltip]="
          'tooltip.playing_video_x_of_n' | translate : { i: index, n: numItems }
        "
        >[{{ index }}]</span
      >
      {{ videoCurrentTime | duration }} / {{ videoDuration | duration }}
    </span>
    <button
      id="btn-previous"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.previous_video' | translate }}"
      [disabled]="selectedAssetId === 0"
      (click)="selectVideo(selectedAssetId - 1)"
    >
      <i class="ri-skip-back-fill"></i>
    </button>
    <button
      id="btn-next"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.next_video' | translate }}"
      [disabled]="selectedAssetId >= playListCount - 1"
      (click)="selectVideo(selectedAssetId + 1)"
    >
      <i class="ri-skip-forward-fill"></i>
    </button>
    <button
      id="btn-select-video"
      mat-icon-button
      class="btn"
      matTooltip="{{ 'button.select_video' | translate }}"
      [disabled]="playListCount <= 1"
      (click)="toggleVideoSelectionOverlay()"
      data-cy="button-select-video"
    >
      <i
        class="ri-film-line"
        [class.text-primary]="showVideoSelectionOverlay$ | async"
        [matBadge]="numItems > 1 ? numItems : null"
        matBadgeSize="small"
      ></i>
    </button>
    <div id="resizeHandle" #resizeHandle></div>
  </div>
</div>

<div *ngIf="isLoading" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
