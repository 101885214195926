import { Injectable } from "@angular/core";
import { FindingFileService, MosaicService, RoiItem } from "@telespot/web-core";
import {
  FindingChange,
  IFinding,
  ISyncedItem,
  IVideoROI,
  Mode,
  POI,
  ProtocolSpecification,
  ROI,
} from "../../state";
import { FindingMapper } from "./finding.mapper";
import { CloudFunctions, Finding, StepTask } from "@telespot/sdk";
import { ISampleItem } from "../../models/i-sample-item";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "any",
})
export class FindingService {
  constructor(
    private _findingFileService: FindingFileService,
    private _mosaicService: MosaicService,
    private _http: HttpClient
  ) {}

  public async saveFindings(
    findings: IFinding[],
    changes: FindingChange<string>[],
    rois: (ROI | POI)[],
    videoRois: IVideoROI[],
    mode: Mode,
    hasSegmentationTasks: boolean,
    protocols: ProtocolSpecification[],
    assetsInfo: ISampleItem[]
  ) {
    const isCopy = (f) => f.id.startsWith("copy:");

    if (mode === Mode.REVIEW) {
      const findingsUnsyncedIds = findings.map((f) =>
        isCopy(f) ? f.id.substring(f.id.indexOf("replace:")) : f.id
      );
      findings = findings.filter(
        (f) => !findingsUnsyncedIds.includes("replace:" + f.id)
      );
    }

    const parseFindings = findings.map((f) =>
      FindingMapper.fromStateFinding(
        f,
        changes,
        rois,
        videoRois,
        protocols,
        assetsInfo
      )
    );

    const remoteFindings = await Finding.saveAll(parseFindings);

    let modifiedFindings = [];

    if (hasSegmentationTasks) {
      const segmentationFindings = remoteFindings.filter(
        (f) => f.type === StepTask.SEGMENTATION
      );
      if (segmentationFindings?.length) {
        modifiedFindings = await Promise.all(
          this.uploadMask(segmentationFindings, findings)
        );
        await Finding.saveAll(modifiedFindings);
      }
    }

    const newChanges = remoteFindings
      .map((ra, i) => ({
        previous: findings[i].id,
        current: ra.id,
        analysisId: ra.analysis.id,
        assetId: ra.analysis.asset?.id,
        version: ra.version,
        type: ra.type,
        uuid: findings[i].uuid,
        maskFileName: modifiedFindings.find((f) => f.id === ra.id)?.data,
        emptyData: ra.data?.content?.length ? false : true,
      }))
      .filter((change) => change.current !== change.previous);

    return newChanges;
  }

  public uploadMask(savedFindings: Finding[], unsyncedFindings: IFinding[]) {
    const segmentationFindings = savedFindings.map(async (f, i) => {
      const src = localStorage.getItem(`localMask/${unsyncedFindings[i].id}`);
      const imageBlob = this._findingFileService.dataURItoBlob(src);

      const file = new File([imageBlob], "mask.png", {
        type: "image/png",
      });
      const maskFileName = await this._findingFileService.upload(
        file,
        f.analysis.id,
        "files/findings"
      );
      localStorage.setItem(`localMask/${f.id}`, src);
      localStorage.removeItem(unsyncedFindings[i].id);

      f.data = { url: maskFileName };

      return f;
    });

    return segmentationFindings;
  }

  public async copyFindings(
    sampleId: string,
    currUserId: string,
    analyst: Parse.Pointer
  ) {
    return await CloudFunctions.copyFindingsFromSample(
      sampleId,
      currUserId,
      analyst
    );
  }

  public extractRoisChanges(
    items: RoiItem[],
    findingInfo: any,
    stateRois,
    refstripItems,
    label: string,
    thrdisp
  ) {
    const rois = items
      .filter((roi) => roi.labels[label] > thrdisp)
      .map((item) => {
        const refItem = refstripItems.find(
          (elem) => elem.assetId === item.assetId
        );
        const findingId = findingInfo.find(
          (info) => info.stepId === item.stepId && info.assetId === item.assetId
        )?.findingId;

        const coords = FindingMapper.denormalizeRoi(refItem, item);
        return { ...coords, cropFileName: item.id, findingId };
      });

    return this.getMatchingRoiIds(stateRois, rois);
  }

  public getMatchingRoiIds(stateRois, mosaicRoisFetched) {
    const changes = [];
    stateRois.forEach((r) => {
      const roi = mosaicRoisFetched.find((mr) => {
        const hasMatchingFindingId = r.labels.some(
          (l) => l.findingId === mr.findingId
        );
        return hasMatchingFindingId && this.isSameROI(mr, r);
      });
      if (roi)
        changes.push({
          id: r.id,
          changes: { cropFileName: roi.cropFileName },
        });
    });

    return changes;
  }

  public isSameROI(roi1, roi2) {
    return (
      roi1.x === roi2.x &&
      roi1.y === roi2.y &&
      roi1.w === roi2.w &&
      roi1.h === roi2.h
    );
  }

  public stillProcessing(syncedFindings: ISyncedItem[], itemsFetched) {
    return itemsFetched.some((item) =>
      syncedFindings.find(
        (f) => f.id === item.findingId && item.findingVersion < f.version
      )
    );
  }

  public fetchCropBlobs(filenames): Observable<Blob>[] {
    return filenames.map((f) => {
      return this._mosaicService.getCrop(f);
    });
  }
}
