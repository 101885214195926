export enum StepTask {
  TEXT = "text",
  OCR = "ocr",
  CLASSIFICATION = "classification",
  ROIDETECTION = "roidetection",
  SEGMENTATION = "segmentation",
  POSITION = "position",
  ROITRACKING = "roitracking",
}
// REVIEW: ad-hoc for big cells filter
export enum LargeCells {
  MEGACARIOCITO = "Megacariocito",
}
export interface PipelineStepParams {
  options: {
    [key: string]: any;
  };
  categorization: {
    category: string;
    options: string[];
  };
  multiple?: string;
}

export enum StepAction {
  USER_EXIT = "user:exit",
  USER_CAPTURE = "user:capture",
  ASSET_CREATED = "asset:created",
  SAMPLE_CREATED = "sample:created",
  ACQUISITION_FRAME = "acquisition:frame",
  ACQUISITION_EXIT = "acquisition:exit",
}

export enum ExecutorEntity {
  ALGORITHM = "algorithm",
  USER = "user",
}
export interface PipelineStepParameters {
  id?: string;
  task: StepTask;
  executorEntity: ExecutorEntity;
  executorId: string;
  order: number;
  trigger: StepAction;
  generateFinding: StepAction;
  params: any;
  pipelineId: string;
}

export class PipelineStep {
  constructor(private attributes: PipelineStepParameters) {}

  public get id(): string {
    return this.attributes.id;
  }

  public get task(): StepTask {
    return this.attributes.task;
  }

  public get executorEntity(): ExecutorEntity {
    return this.attributes.executorEntity;
  }

  public get executorId(): string {
    return this.attributes.executorId;
  }

  public get order(): number {
    return this.attributes.order;
  }

  public get trigger(): StepAction {
    return this.attributes.trigger;
  }

  public get generateFinding(): StepAction {
    return this.attributes.generateFinding;
  }

  public get params(): any {
    return this.attributes.params;
  }

  public get pipelineId(): string {
    return this.attributes.pipelineId;
  }
}
